const plugin = require('tailwindcss/plugin');

module.exports = {
  // purge: {
  //     enabled: true,
  //     content: ['./**/*.js'],
  // },
  theme: {
    important: true,
    fontFamily: {
      display: ["Roboto"],
      sans: ["Roboto"],
      serif: ["Roboto"],
      mono: ["Roboto"],
      body: ["Roboto"],
      mywaybuy: ["Saira"],
    },
    letterSpacing: {
      wide: "0.42pt",
      wider: "1pt",
      widest: "1.68pt",
    },
    minHeight: {
      0: "0",
      254: "254px",
    },

    extend: {

      borderRadius: {
        none: "0",
        lg: "0.5rem",
        xl: "0.75rem",
        "2xl": "1rem",
        "3xl": "1.5rem",
      },
      inset: {
        "-16": "4rem",
        "-14": "-3.5rem",
        "-12": "-3rem",
        "-10": "-2.5rem",
        "-8": "-2rem",
        "-6": "-1.5rem",
        "-5": "-1.25rem",
        "-4": "-1rem",
        4: "1rem",
        5: "1.25rem",
        6: "1.5rem",
        10: "2.5rem",
        12: "3rem",
        14: "3.5rem",
        16: "4rem",
        "1/2": "50%",
        "1/3": "33.333%",
        "2/3": "66.666%",
        "1/4": "25%",
      },
      spacing: {
        9: "2.25rem",
        14: "3.5rem",
        28: "7rem",
        60: "15rem",
        140: "35rem",
      },
      rotate: {
        6: "6deg",
        12: "12deg",
      },
      translate: {
        "1/3": "33.333%",
      },
      fontSize: {
        "7xl": "5rem",
        10: "10px",
        12: "12px",
        14: "14px",
        16: "16px",
        18: "18px",
        19: "19px",
        20: "20px",
        24: "24px",
        30: "30px",
        34: "34px",
        36: "36px",
        40: "40px",
        44: "44px",
        70: "70px",
        100: "100px",
      },
      colors: {
        color_one: "#2d375a",
        color_two: "#000000",
        color_three: "#707070",
        color_four: "#E3E8F0",
        color_five: "#F0F2F7",
        color_six: "#FFFFFF",
        color_error: "#E30000",
        color_success: "#5ad2aa",
        color_mywaybuy_one: "#00243b",
        color_mywaybuy_two: "#419131",
        color_mywaybuy_three: "#f2faf0",
        color_mywaybuy_four: "#607e96",
        color_mywaybuy_five: "#e5e7eb",
        color_mywaybuy_lightgreen: "#85CD29",
        color_mywaybuy_darkgreen: "#439331",
        color_mywaybuy_lightorange: "#fff9e7",
        color_mywaybuy_error: "#ff4d56",
        color_mywaybuy_warning: "#ffb845",
        color_mywaybuy_success: "#419131",
      },
    },
  },
  variants: {},
  plugins: [
    plugin(function ({ addBase, config }) {
      addBase({
        ".SelectField__indicator-separator": { display: "none !important" },
        ".SelectField__dropdown-indicator": {
          color: `${config("theme.colors.color_one")} !important`,
        },
        ".SelectFieldError__control ": {
          borderColor: `${config("theme.colors.color_error")} !important`,
          borderRadius: "6px !important",
          backgroundColor: `${config("theme.colors.color_five")} !important`,
          color: "#B9BED1 !important",
          minHeight: "45px !important",
          paddingLeft: "8px",
          paddingRight: "8px",
          cursor: "pointer !important",
        },
        ".SelectField__control": {
          borderRadius: "6px !important",
          borderColor: "#E3E8F0 !important",
          backgroundColor: `${config("theme.colors.color_five")} !important`,
          color: "#B9BED1 !important",
          minHeight: "45px !important",
          paddingLeft: "8px",
          paddingRight: "8px",
          cursor: "pointer !important",
          fontSize: "14px",
        },
        ".SelectField__placeholder": {
          color: "#a0aec0 !important",
          fontSize: "14px",
          overflow: "hidden !important",
          textOverflow: "ellipsis !important",
          whiteSpace: "nowrap !important",
        },
        // '.vehicle-summary-container': {
        //     backgroundColor: `${config('theme.colors.color_one')} !important`,
        //     color: 'white',
        // },
        // '.vehicle-summary-item': {
        //     borderColor: 'white'
        // }
      });
    }),
  ],
};
