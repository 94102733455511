import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { keys, map, orderBy } from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const Fuel = () => {
  const { inspection, data, setField, setNextVehicleDataStep } =
    useContext(DataContext);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "car-fuel-type",
    toolStepNumber: "5",
    requestType: "car-quotation",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};

    if (!inspection.fuel) {
      errors["fuel"] = t("Please select fuel type");
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "car-fuel-type",
        toolStepNumber: "5",
        requestType: "car-quotation",
        errorType: "user-error",
        errorMessage: errors.fuel,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const handleFuelClick = (e, fuel) => {
    setField("fuel", fuel.key);

    let aTrack = analyticstracker();
    aTrack.trackInteraction(e);

    setNextVehicleDataStep();
  };

  const fuelOptions =
    data && data.fuels ? orderBy(data.fuels, ["description"], ["asc"]) : [];

  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Fuel")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t("Select the fuel of your vehicle")}:
      </div>

      <div className="flex flex-wrap mb-10">
        {map(fuelOptions, (fuel, i) => {
          const selected = fuel.key === inspection.fuel;
          const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "car-fuel-type",
            toolStepNumber: "5",
            requestType: "car-quotation",
            toolStepOption: fuel.description,
          });
          return (
            <div
              key={`fuel_${fuel.key}`}
              className={`w-1/2 mb-2 ${i % 2 !== 1 ? "pr-2" : ""}`}
            >
              <div
                className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                  selected
                    ? "border-color_one text-color_one"
                    : "border-color_five text-color_three"
                } hover:border-color_one hover:text-color_one truncate`}
                style={{ height: 70 }}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                data-tracking-commerce={tagArgsCommerce}
                onClick={(e) => handleFuelClick(e, fuel)}
              >
                {fuel.description}
              </div>
            </div>
          );
        })}
      </div>

      <WizardError errors={errors} />

      <WizardButtons validatorFunction={validator} />
    </div>
  );
};

export default Fuel;
