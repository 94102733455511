import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDealerFromUrl } from "../utils";
import VehicleData from "./vehicle/VehicleData";
import { isMobile } from "react-device-detect";
import DataContext from "../data/context";
import QueryString from "query-string";
import {
  clearVehicleStorage,
  inspectionFromStorage,
  removeInspectionFromStorage,
} from "../data/localstorage";
import { TAGMANAGER_PAGE_ARGS, getPreviousPageData } from "../tagManager";

const Home = () => {
  const { setInspectionPending, setData, dealerKey, dealerConfig } =
    useContext(DataContext);
  const location = useLocation();

  let history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const params = QueryString.parse(window.location.search);
    if (params.key) {
      const data = inspectionFromStorage(params.key);
      if (data) {
        setData(data);
      }
      history.replace({
        ...location,
        search: "",
      });
      removeInspectionFromStorage(params.key);
    } else {
      clearVehicleStorage();
    }

    setInspectionPending(true);
  }, []);

  const getBackgroundImageUrl = (dealerConfig) => {
    if (dealerConfig.headerImage && dealerConfig.headerImage !== "") {
      return `url(${window.location.origin}/branding/${dealerConfig.headerImage})`;
    } else {
      return `url(${require("../assets/header1.jpg")})`;
    }
  };

  const prevPageData = getPreviousPageData(window.dataLayer);

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    section: "car-quotation",
    language: i18n.language,
    previousBrand: prevPageData?.pageInfo?.brand
      ? prevPageData?.pageInfo?.brand
      : "",
    previousPageFullUrl: prevPageData?.pageInfo?.pageFullUrl
      ? prevPageData?.pageInfo?.pageFullUrl
      : "",

    previousPageName: prevPageData?.pageInfo?.pageName
      ? prevPageData?.pageInfo?.pageName
      : "",
    previousPageType: prevPageData?.pageInfo?.pageType
      ? prevPageData?.pageInfo?.pageType
      : "",
    previousPageUrl: prevPageData?.pageInfo?.pageUrl
      ? prevPageData?.pageInfo?.pageUrl
      : "",
    previousPlatform: prevPageData?.pageInfo?.platform
      ? prevPageData?.pageInfo?.platform
      : "",
  });

  return (
    <div
      className="flex flex-1 justify-center"
      data-tracking-event="page-impression"
      data-tracking-info={tagInfo}
    >
      <div className="w-full">
        {!isMobile && (
          <div
            className="header-home flex flex-col bg-color_one text-white items-center justify-center text-center pl-8 pr-8 relative"
            style={{
              height: 156,
              background: `${getBackgroundImageUrl(
                dealerConfig
              )} no-repeat center`,
              backgroundSize: "cover",
            }}
          >
            <div
              className="header-home-overlay bg-color_one absolute flex flex-1 w-full opacity-75"
              style={{ height: 156 }}
            />

            <div className="absolute p-8">
              <div className="font-bold tracking-wider text-34 md:text-44 ">
                {t("Online vehicle valuation")}
              </div>
              <div className="text-md md:text-lg mt-6 ">
                {t("We guarantee a transparent and safe takeover.")}
              </div>
            </div>
          </div>
        )}

        <div className="w-full md:flex md:justify-center">
          <div className="w-full mb-12" style={{ maxWidth: 780 }}>
            <VehicleData
              onComplete={(key) => {
                history.push(
                  `/${getDealerFromUrl(
                    window.location.pathname
                  )}/valuation/${key}`
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
