import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {ClientContext} from "graphql-hooks";
import DataContext from "../data/context";


const LanguagePicker = ({ activeClass, inactiveClass, additionalClasses }) => {
  const { i18n } = useTranslation();
  const client = useContext(ClientContext);
  const { dealerConfig } = useContext(DataContext);

  const changeLanguage = (lng) => {
    client.setHeader("Accept-Language", lng);
    i18n.changeLanguage(lng);

    if (dealerConfig.reloadOnLanguageChange) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("language")) {
        urlParams.delete("language");
      }

      const hasParams = urlParams.toString() !== "";
      if (hasParams) {
        window.location = `${window.location.origin}${
          window.location.pathname
        }?${urlParams.toString()}`;
      } else {
        window.location = `${window.location.origin}${window.location.pathname}`;
      }
    }
  };

  return (
    <div className={`${additionalClasses} flex`}>
      <div
        className={`cursor-pointer uppercase ${
          i18n.language && i18n.language.startsWith("nl")
            ? activeClass
            : inactiveClass
        }`}
        onClick={() => changeLanguage("nl")}
      >
        NL
      </div>
      <span className="mx-2 -mt-1 text-20">|</span>
      <div
        className={`cursor-pointer uppercase ${
          i18n.language && i18n.language.startsWith("fr")
            ? activeClass
            : inactiveClass
        }`}
        onClick={() => changeLanguage("fr")}
      >
        FR
      </div>
    </div>
  );
};

LanguagePicker.defaultProps = {
  activeClass: "text-color_one font-bold",
  inactiveClass: "text-color_three",
  additionalClasses: "text-14 text-color_three font-bold",
};

export default LanguagePicker;
