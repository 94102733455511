import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataContext from "../../data/context";

const ValuationHelp = () => {
  const { t } = useTranslation();
  const { dealerKey } = useContext(DataContext);
  const history = useHistory();

  return (
    <div className="w-full sm:w-1/2">
      <h3 className="font-bold text-20 md:text-24">
        {t("Do you have a question?")}
      </h3>
      <p className="text-color_mywaybuy_four mb-5">
        {t(
          "Fill out the contact form with your details and we will contact you"
        )}
        !
      </p>
      <button
        className="w-full py-3 px-5 text-white text-16 font-bold cursor-pointer mb-5"
        style={{
          minHeight: 40,
          borderRadius: 8,
          background: "rgb(111,187,56)",
          background:
            "linear-gradient(90deg, rgba(111,187,56,1) 0%, rgba(70,147,51,1) 100%)",
        }}
        onClick={() => history.push(`/${dealerKey}/contact`)}
      >
        {t("Contact customer service")}
      </button>
      <div className="flex items-center justify-center text-gray-300 text-40">
        -
        <span className="text-16 text-color_mywaybuy_four px-2">{t("or")}</span>
        -
      </div>
      <div className="flex items-center justify-center">
        <a className="text-color_mywaybuy_two underline" href="tel:+3228803303">
          +32 2 880 33 03
        </a>
      </div>
    </div>
  );
};

export default ValuationHelp;
