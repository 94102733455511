import React from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

const LandingLocations = () => {
  const { t } = useTranslation();

  const LOCATIONS = {
    zaventem: {
      key: "location-mywaybuy-zaventem",
      link: "https://goo.gl/maps/J2qYz2QEFk8a78Tz5",
      name: `MY WAY BUY - ${t("Zaventem")}`,
      street: "Fabrieksstraat",
      number: "61",
      postal: "1930",
      city: t("Zaventem"),
    },
    herkdestad: {
      key: "location-dekra-herk-de-stad",
      link: "http://maps.google.com/?q=Steenweg 3,+3540&nbsp;Herk-de-Stad",
      name: `DEKRA - ${t("Herk-de-Stad")}`,
      street: "Steenweg",
      number: "3",
      postal: "3540",
      city: t("Herk-de-Stad"),
    },
    roeselare: {
      key: "location-dekra-roeselare",
      link: "http://maps.google.com/?q=Groenestraat+244+A,+8800&nbsp;Roeselare",
      name: `DEKRA  - ${t("Roeselare")}`,
      street: "Meensesteenweg",
      number: "356 C",
      postal: "8800",
      city: t("Roeselare"),
    },
    gosselies: {
      key: "location-ced-gosselies",
      link: "http://maps.google.com/?q=Av.+Jean+Mermoz+30,+6041+Charleroi",
      name: `CED - ${t("Gosselies")}`,
      street: "Aveneu Jean Mermoz",
      number: "30",
      postal: "6041",
      city: t("Gosselies"),
    },
    herstal: {
      key: "location-dekra-herstal",
      link: "http://maps.google.com/?q=Deuxième+Avenue+31,+4040&nbsp;Herstal",
      name: `DEKRA - ${t("Herstal")}`,
      street: "Deuxième Avenue",
      number: "31",
      postal: "4040",
      city: t("Herstal"),
    },
    genkCed: {
      key: "location-ced-genk",
      link: "https://www.google.com/maps/place/Experten+Associatie+Limburg/@50.9244006,5.5347661,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0d92cbc5b7b69:0xe8b047a04a98668d!8m2!3d50.92393!4d5.5364556",
      name: `CED - ${t("Genk")}`,
      street: t("Nieuwpoortlaan"),
      number: "12.6",
      postal: "3600",
      city: t("Genk"),
    },

    antwerpCed: {
      key: "location-ced-antwerp",
      link: "http://maps.google.com/?q=Noorderlaan+87,+2030+Antwerpen",
      name: `CED - ${t("Antwerp")}`,
      street: "Noorderlaan",
      number: "87",
      postal: "2030",
      city: t("Antwerp"),
    },
    stPietersLeeuwCed: {
      key: "location-ced-stPietersLeeuw",
      link: "http://maps.google.com/?q=Generaal+Lemanstraat+2,+1600+Sint-Pieters-Leeuw/",
      name: `CED - Sint-Pieters-Leeuw`,
      street: "Generaal Lemanstraat",
      number: "2A",
      postal: "1600",
      city: "Sint-Pieters-Leeuw",
    },
    ghentCed: {
      key: "location-ced-ghent",
      link: "http://maps.google.com/?q=Loofblommestraat+51,+9051+Gent",
      name: `CED - ${t("Ghent")}`,
      street: "Loofblommestraat",
      number: "51",
      postal: "9051",
      city: t("Sint-Denijs-Westrem"),
    },
    luikGexa: {
      key: "location-gexa-luik",
      link: "https://www.google.com/maps/place/Rue+Georges+Truffaut+60,+4432+Ans/@50.6758596,5.5130916,17z/data=!3m1!4b1!4m6!3m5!1s0x47c0fb0add7b7e35:0x70f2c48d457121c!8m2!3d50.6758596!4d5.5152803!16s%2Fg%2F11cp9rpbz1",
      name: `GEXA - ${t("Luik")}`,
      street: t("Rue Georges Truffaut "),
      number: "60",
      postal: "4432",
      city: t("Alleur"),
    },
  };

  const renderLocationAddress = (
    key,
    link,
    name,
    street,
    number,
    postal,
    city
  ) => {
    return (
      <div
        key={key}
        className="dekra-address w-full px-2 my-3 md:px-4 md:my-4 relative z-10 transition-all"
      >
        <a
          href={link}
          target="_blank"
          //className="block rounded-2xl shadow-none md:hover:shadow-xl py-4 md:px-6 transition-all"
          className="block"
          onClick={() => {
            //Track buttonclick
            let aTrack = analyticstracker();
            let trackingInfo = TAGMANAGER_BUTTON_ARGS({
              buttonName: "to-inspectionpoint",
              buttonText: name,
              buttonType: "button",
              buttonCategory: "outbound-link",
              buttonTarget: link,
              buttonPosition: "home-inspection-points",
            });
            let event = {
              event: "button-click",
              info: JSON.parse(trackingInfo),
            };
            aTrack.trackEvent(event);
          }}
        >
          <p className="text-lg font-bold mb-1 leading-tight">{name}</p>
          <p className="text-sm text-color_mywaybuy_four leading-normal">
            {street} {number}
          </p>
          <p className="text-sm text-color_mywaybuy_four leading-normal">
            <span>{postal} </span>
            <strong>{city}</strong>
          </p>
        </a>
      </div>
    );
  };

  return (
    <div className="py-10 md:py-14 my-16 px-4 mx-auto max-w-screen-xl">
      <h3 className="text-left uppercase text-color_mywaybuy_success font-medium tracking-wider">
        {t("Inspection points")}
      </h3>
      <div className="relative w-full">
        <div className="lg:w-3/5 lg:relative lg:z-10">
          <h2 className="text-left text-4xl md:text-5xl max-w-2xl py-4 font-bold leading-tight">
            {t("Available inspection points in Belgium")}
          </h2>
          <p className="text-left text-color_mywaybuy_four text-base leading-normal max-w-2xl">
            {t(
              "With My Way Buy you can select the closest inspection location, the choice is yours."
            )}
            <strong>
              {" "}
              {t(
                "Our experts confirm the state of your vehicle and validate the offer."
              )}
            </strong>
          </p>
          {/* <div className="flex flex-col md:flex-row py-8"> */}
          <div className="relative pb-8">
            {/* <div className="flex-1 flex-row md:flex-col p-4"> */}
            <div className="w-full sm:w-2/3 sm:my-0 sm:mx-auto lg:hidden p-4">
              <img
                src={require("../../assets/landing-page/belgium.svg")}
                alt="belgium map with inspection points"
                className="w-full"
              />
            </div>
            <div className="flex-1">
              {/* <div className="flex flex-wrap"> */}
              <div className="grid grid-cols-2 -mx-2 md:grid-cols-3 md:-mx-4 md:divide-x md:divide-color_mywaybuy_five lg:grid-cols-2 xl:grid-cols-3">
                {map(LOCATIONS, (location) => {
                  return renderLocationAddress(
                    location.key,
                    location.link,
                    location.name,
                    location.street,
                    location.number,
                    location.postal,
                    location.city
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block lg:absolute lg:right-0 lg:top-0 lg:w-6/12 lg:mt-32 lg:z-0 xl:w-5/12 xl:mt-24">
          <img
            src={require("../../assets/landing-page/belgium.svg")}
            alt="belgium map with inspection points"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingLocations;
