import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { hasValue, isValidEmail } from "../../validators";
import DataContext from "../../data/context";
import {useMutation} from "graphql-hooks";
import TextField from "../input/TextField";
import NumericField from "../input/NumericField";
import TextAreaField from "../input/TextAreaField";

export const submitContactformMutation =
    `mutation submitContactformMutation($data: SubmitContactformType!) { 
        submitContactform(data: $data) {
            ok,            
        }
    }`;

const ContactForm = () => {
  const {inspection, dealerKey} = useContext(DataContext);

  const [submitMutation, {loading: submitLoading, error: submitError}] = useMutation(submitContactformMutation);
  const [submittedState, setSubmittedState] = useState();
  const [firstName, setFirstName] = useState(inspection?.firstName);
  const [lastName, setLastName] = useState(inspection?.lastName);
  const [email, setEmail] = useState(inspection?.email);
  const [phone, setPhone] = useState(inspection?.telephone);
  const [manufacturer, setManufacturer] = useState(inspection?.make);
  const [model, setModel] = useState(inspection?.model);
  const [mileage, setMileage] = useState(inspection?.mileage);
  const [registrationYear, setRegistrationYear] = useState(inspection?.firstRegistrationYear);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState([]);

  const { t } = useTranslation();

  var _hsq = (window._hsq = window._hsq || []); //Needed for hubspot tracking

  useEffect(() => {
    if (submittedState) {
      const timer = setTimeout(() => {
        setSubmittedState(false)
      }, 2000);
      return () => clearTimeout(timer);
    }

  }, [submittedState]);

  const validate = () => {
    let currentErrors = [];
    if (!hasValue(firstName)) {
      currentErrors["firstName"] = t("Please fill in your first name");
    }
    if (!hasValue(lastName)) {
      currentErrors["lastName"] = t("Please fill in your last name");
    }
    if (!hasValue(email) || !isValidEmail(email)) {
      currentErrors["email"] = t("Please fill in a valid email address");
    }
    if (!hasValue(phone)) {
      currentErrors["phone"] = t("Please fill in your phone number");
    }
    if (!hasValue(manufacturer)) {
      currentErrors["manufacturer"] = t(
        "Please fill in the manufacturer of your car"
      );
    }
    if (!hasValue(model)) {
      currentErrors["model"] = t("Please fill in the model of your car");
    }
    if (!hasValue(mileage) || isNaN(mileage)) {
      currentErrors["mileage"] = t("Please fill in the mileage of your car");
    }
    if (!hasValue(registrationYear) || isNaN(registrationYear)) {
      currentErrors["registrationYear"] = t(
        "Please fill in the year of registration of your car"
      );
    }
    if (!hasValue(reason)) {
      currentErrors["reason"] = t(
        "Please give a further explanation why you want to contact us"
      );
    }
    setErrors(currentErrors);

    return Object.keys(errors).length <= 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validate();
    if (valid) {
      _hsq.push([
        "identify",
        {
          email: email,
        },
      ]);
      submitMutation({
                variables: {
                    data: {
                        inspectionKey: inspection?.key,
                        customerKey: dealerKey,
                        language: i18n.language,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        telephone: phone,
                        make: manufacturer,
                        model: model,
                        mileage: mileage,
                        firstRegistrationYear: registrationYear,
                        message: reason
                    }
                }
            }).then((response) => {
              if (response.data?.submitContactform.ok) {
                setSubmittedState(true)
            }})
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pb-10 border-b border-color_mywaybuy_five mb-10">
        <TextField
          label={t("First name")}
          value={firstName}
          onChange={(value) => setFirstName(value)}
          required={true}
          placeholder={"John"}
          error={errors["firstName"]}
        />
        <TextField
          label={t("Last name")}
          value={lastName}
          onChange={(value) => setLastName(value)}
          required={true}
          placeholder={"Doe"}
          error={errors["lastName"]}
        />
        <TextField
          label={t("Email")}
          value={email}
          onChange={(value) => setEmail(value)}
          required={true}
          placeholder={"john.doe@email.com"}
          error={errors["email"]}
        />
        <TextField
          label={t("Phone number")}
          value={phone}
          onChange={(value) => setPhone(value)}
          required={true}
          placeholder={"+32 000 00 00 00"}
          error={errors["phone"]}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pb-10 border-b border-color_mywaybuy_five mb-10">
        <TextField
          label={t("Car manufacturer")}
          value={manufacturer}
          onChange={(value) => setManufacturer(value)}
          required={true}
          placeholder={"Volkswagen"}
          error={errors["manufacturer"]}
        />
        <TextField
          label={t("Car model")}
          value={model}
          onChange={(value) => setModel(value)}
          required={true}
          placeholder={"Golf"}
          error={errors["model"]}
        />
        <NumericField
          label={t("Car mileage")}
          value={mileage}
          onChange={(value) => setMileage(value)}
          required={true}
          placeholder={10000}
          error={errors["mileage"]}
        />
        <NumericField
          label={t("Year of registration")}
          value={registrationYear}
          onChange={(value) => setRegistrationYear(value)}
          required={true}
          placeholder={new Date().getFullYear()}
          error={errors["registrationYear"]}
          thousandSeparator={""}
        />
      </div>
      <div className="mb-10">
        <TextAreaField
          label={t("Reason of contact")}
          value={reason}
          onChange={(value) => setReason(value)}
          required={true}
          errorText={errors["reason"]}
        />
      </div>
      <button
        type={"submit"}
        className={
          "cursor-pointer block md:inline-block w-full md:w-auto text-center text-white text-lg font-bold px-8 py-4 md:py-3 bg-color_mywaybuy_darkgreen rounded-lg transition duration-100 transform shadow-far hover:shadow-close hover:scale-95"
        }
      >
        {submitLoading ? t("Sending..."): submittedState ? t("Form submitted!"):t("Submit")}
      </button>
    </form>
  );
};

export default ContactForm;
