import React from "react";
import { useTranslation } from "react-i18next";
import { getDealerFromUrl } from "../../utils";
import { useHistory } from "react-router-dom";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

import LandingButton from "./LandingButton";

const LandingCta = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="py-16 my-10 bg-color_mywaybuy_three rounded-3xl  mx-auto max-w-screen-xl">
      <h3 className="text-center uppercase text-color_mywaybuy_success font-medium tracking-wider">
        {t("Sell your car")}
      </h3>
      <h2 className="text-center text-4xl md:text-5xl max-w-2xl py-4 mx-auto font-bold leading-tight">
        {t("Looking for the best offer for your car")}?{" "}
        <span className="text-color_mywaybuy_two">
          {t("MyWay Buy is the answer")}
        </span>
        .
      </h2>
      <div className="text-center py-10 px-4">
        <LandingButton
          onClick={() => {
            //Track buttonclick
            let aTrack = analyticstracker();
            let trackingInfo = TAGMANAGER_BUTTON_ARGS({
              buttonName: "to-car-quotation",
              buttonText: t("Receive your free offer"),
              buttonType: "button-cta",
              buttonCategory: "inbound-link",
              buttonTarget: `/${getDealerFromUrl(
                window.location.pathname
              )}/inspection`,
              buttonPosition: "home-cta-block",
            });
            let event = {
              event: "button-click",
              info: JSON.parse(trackingInfo),
            };
            aTrack.trackEvent(event);

            history.push(
              `/${getDealerFromUrl(window.location.pathname)}/inspection`
            );
          }}
          text={t("Receive your free offer")}
        />
      </div>
    </div>
  );
};

export default LandingCta;
