import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "graphql-hooks";
import { keys } from "lodash";
import analyticstracker from "analyticstracker";

import DataContext from "../../../data/context";
import WizardButtons from "../WizardButtons";
import { TextField } from "../../input";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const vinMutation = `mutation SetVin($key: String!, $vin: String!) { 
    setVin(key: $key vin: $vin) {
        ok,           
    }
}`;

const VehicleIdentification = () => {
  const { inspection, setField, inspectionWizardStep } =
    useContext(DataContext);
  const [setVinMutation] = useMutation(vinMutation);

  const [showHelp, setShowHelp] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const hostname = window.location.hostname;

  const stepNumber =
    inspectionWizardStep !== null && inspectionWizardStep !== undefined
      ? "1" //inspectionWizardStep
      : "9";

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "car-vin-number",
    toolStepNumber: stepNumber,
    toolName:
      hostname.includes("myway") && stepNumber === "1"
        ? "myway-buy-inspection"
        : "",
    toolType:
      hostname.includes("myway") && stepNumber === "1" ? "inspection-form" : "",
    requestType:
      hostname.includes("myway") && stepNumber === "1" ? "inspection" : "",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};
    const trackError = (error) => {
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "car-vin-number",
        toolStepNumber: stepNumber,
        errorType: "user-error",
        errorMessage: error,
        toolType:
          hostname.includes("myway") && stepNumber === "1"
            ? "inspection-form"
            : "",
        requestType:
          hostname.includes("myway") && stepNumber === "1" ? "inspection" : "",
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    };

    if (!inspection.vin) {
      errors["vehicleIdentification"] = t(
        "Please fill out the car's vin number"
      );
      trackError(errors.vehicleIdentification);
    }

    if (inspection.vin && inspection.vin.trim().length !== 17) {
      errors["vehicleIdentification"] = t(
        "The vin number must be 17 characters long."
      );
      trackError(errors.vehicleIdentification);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    const valid = validate();

    if (valid) {
      return setVinMutation({
        variables: {
          key: inspection.key,
          vin: inspection.vin,
        },
      }).then((result) => {
        if (result?.data?.setVin?.ok) {
          return true;
        }
        return false;
      });
    }
  };

  return (
    <div
      className="ml-8 mr-8 mt-0 lg:mt-10"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Vehicle identification")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t(
          "To identify the type and characteristics of your vehicle, please fill in the VIN number"
        )}
      </div>

      <div className="flex flex-col justify-center relative mb-10">
        <div style={{ width: 300 }}>
          <TextField
            placeholder={t("Chassisnumber")}
            value={inspection.vin}
            onChange={(value) => setField("vin", value)}
            maxLength="17"
            required={true}
            error={errors["vehicleIdentification"]}
          />
        </div>
        <div>
          <div className="text-16 leading-6 mb-4 mt-6 underline">
            {t("How to find my VIN number? ")}
          </div>

          <div className="flex flex-row mb-2">
            - {t("Check for a code on your windshield")}
            <div className="ml-1">
              <i
                className="fal fa-question-circle cursor-pointer"
                onMouseEnter={() => setShowHelp("windshield")}
                onMouseLeave={() => setShowHelp(false)}
                onClick={() => setShowHelp(!showHelp)}
              />
            </div>
          </div>
          <div className="flex flex-row mb-2">
            - {t("Check for a code inside the driver's side door")}
            <div className="ml-1">
              <i
                className="fal fa-question-circle cursor-pointer"
                onMouseEnter={() => setShowHelp("door")}
                onMouseLeave={() => setShowHelp(false)}
                onClick={() => setShowHelp(!showHelp)}
              />
            </div>
          </div>
          <div className="flex flex-row mb-2">
            - {t("Check for a code on your registration certificate")}
            <div className="ml-1">
              <i
                className="fal fa-question-circle cursor-pointer"
                onMouseEnter={() => setShowHelp("certificate")}
                onMouseLeave={() => setShowHelp(false)}
                onClick={() => setShowHelp(!showHelp)}
              />
            </div>
          </div>
        </div>

        {showHelp && (
          <div
            className="absolute mt-2 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50"
            style={{ top: "100%" }}
          >
            <img
              src={require(`../../../assets/help-images/vin_${showHelp}.jpg`)}
              alt="Chassisnumber"
            />
          </div>
        )}
      </div>
      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "car-vin-number",
          toolStepNumber: stepNumber,
          toolName:
            hostname.includes("myway") && stepNumber === "1"
              ? "myway-buy-inspection"
              : "",
          toolType:
            hostname.includes("myway") && stepNumber === "1"
              ? "inspection-form"
              : "",
          requestType:
            hostname.includes("myway") && stepNumber === "1"
              ? "inspection"
              : "",
        })}
        trackingCommerce={tagArgsCommerce}
      />
    </div>
  );
};

export default VehicleIdentification;
