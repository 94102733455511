import React, { useState, useEffect, useRef } from "react";

import LandingHero from "./landing-page/LandingHero";
import LandingUniqueSellingPoints from "./landing-page/LandingUniqueSellingPoints";
import LandingSteps from "./landing-page/LandingSteps";
import LandingLocations from "./landing-page/LandingLocations";
import LandingDifferentiation from "./landing-page/LandingDifferentiation";
import LandingCta from "./landing-page/LandingCta";
import LandingFaq from "./landing-page/LandingFaq";

import { useTranslation } from "react-i18next";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS, getPreviousPageData } from "../tagManager";
import { useIntersection } from "../utils";

const LandingPage = () => {
  const { t, i18n } = useTranslation();

  const advantagesRef = useRef();
  const howRef = useRef();
  const faqRef = useRef();
  const footerRef = useRef();

  const advantagesInViewport = useIntersection(advantagesRef);
  const howInViewport = useIntersection(howRef);
  const faqInViewport = useIntersection(faqRef);
  const footerInViewport = useIntersection(footerRef);

  useEffect(() => {
    if (advantagesInViewport) {
      let cbiTrack = analyticstracker();
      cbiTrack.trackEvent({
        event: "content-block-impression",
        info: {
          containerName: "advantages",
          containerPosition: "",
          containerVersion: "",
        },
      });
    }
  }, [advantagesInViewport]);

  useEffect(() => {
    if (howInViewport) {
      let cbiTrack = analyticstracker();
      cbiTrack.trackEvent({
        event: "content-block-impression",
        info: {
          containerName: "how-it-works",
          containerPosition: "",
          containerVersion: "",
        },
      });
    }
  }, [howInViewport]);

  useEffect(() => {
    if (faqInViewport) {
      let cbiTrack = analyticstracker();
      cbiTrack.trackEvent({
        event: "content-block-impression",
        info: {
          containerName: "faq",
          containerPosition: "",
          containerVersion: "",
        },
      });
    }
  }, [faqInViewport]);

  useEffect(() => {
    if (footerInViewport) {
      let cbiTrack = analyticstracker();
      cbiTrack.trackEvent({
        event: "content-block-impression",
        info: {
          containerName: "footer",
          containerPosition: "",
          containerVersion: "",
        },
      });
    }
  }, [footerInViewport]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("page-impression");
  }, []);

  const prevPageData = getPreviousPageData(window.dataLayer);

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    pageName: "myway-buy/home",
    section: "home",
    language: i18n.language,
    previousBrand: prevPageData?.pageInfo?.brand
      ? prevPageData?.pageInfo?.brand
      : "",
    previousPageFullUrl: prevPageData?.pageInfo?.pageFullUrl
      ? prevPageData?.pageInfo?.pageFullUrl
      : "",

    previousPageName: prevPageData?.pageInfo?.pageName
      ? prevPageData?.pageInfo?.pageName
      : "",
    previousPageType: prevPageData?.pageInfo?.pageType
      ? prevPageData?.pageInfo?.pageType
      : "",
    previousPageUrl: prevPageData?.pageInfo?.pageUrl
      ? prevPageData?.pageInfo?.pageUrl
      : "",
    previousPlatform: prevPageData?.pageInfo?.platform
      ? prevPageData?.pageInfo?.platform
      : "",
  });

  return (
    <div
      className="flex flex-1 justify-center font-mywaybuy text-color_mywaybuy_one"
      data-tracking-event="page-impression"
      data-tracking-info={tagInfo}
    >
      <div className="w-full">
        <section>
          {/* Hero with title, image and CTA */}
          <LandingHero />
        </section>
        <main className="overflow-x-hidden lg:overflow-x-visible">
          <section id="who" ref={advantagesRef}>
            {/* 3 main reasons to request an offer */}
            <LandingUniqueSellingPoints />
          </section>
          <section id="how" ref={howRef}>
            {/* Steps to take after receiving an offer */}
            <LandingSteps />
          </section>
          <section>
            {/* Locations of service points with map */}
            <LandingLocations />
          </section>
          <section>
            {/* Differentiation between selling to a dealer or selling online  */}
            <LandingDifferentiation />
          </section>
          <section>
            {/* Block with CTA to inspection page */}
            <LandingCta />
          </section>
          <section id="faq" ref={faqRef}>
            {/* FAQ / Q&A */}
            <LandingFaq />
          </section>
          <div ref={footerRef}></div>
        </main>
      </div>
    </div>
  );
};

export default LandingPage;
