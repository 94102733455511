import React from "react";
import { useTranslation } from "react-i18next";

const ValuationMessage = ({ hasValuation }) => {
  const { t } = useTranslation();

  return (
    <div
      className="text-color_mywaybuy_four bg-color_mywaybuy_five p-5 md:p-8 text-16 my-10"
      style={{ borderRadius: 8 }}
    >
      {hasValuation ? (
        <p
          className="leading-6"
          dangerouslySetInnerHTML={{
            __html: t(
              "Based on your data, we were able to make a first takeover price estimation for your car. {{styling}} Attention! {{stylingEnd}} Depending on a number of unknown factors such as additional options, damage, ... this amount can range between the minimum and maximum value.",
              {
                styling: '<span class="font-bold">',
                stylingEnd: "</span />",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></p>
      ) : (
        <p
          className="leading-6 text-center"
          dangerouslySetInnerHTML={{
            __html: t(
              "Unfortunately we were not able to calculate a takeover price with this information. Please fill out the detailed inspection and we will get back to you with a correct price in 48hrs.",
              {
                styling: '<span class="font-bold">',
                stylingEnd: "</span />",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></p>
      )}
    </div>
  );
};

export default ValuationMessage;
