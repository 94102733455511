import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "graphql-hooks";
import DataContext from "../../data/context";
import { LoadingIndicator } from "../indicators";

const limitQuery = `query valuationLimits($key: String!) {
  valuationLimits (key: $key) {
	minPrice
	maxAgeMonths
	maxKilometers
}
}`;

export default function ValuationOutsideLimits() {
  const { inspection } = useContext(DataContext);
  const { t } = useTranslation();
  const { loading, data, refetch } = useQuery(limitQuery, {
    variables: { key: inspection.key },
  });

  if (loading) {
    return (
      <div className="flex flex-1 items-center justify-center w-screen h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="pt-14 md:pt-14 px-4 bg-gradient-to-b from-color_mywaybuy_background flex-1">
      <div className="mx-auto flex flex-col md:flex-row max-w-screen-xl">
        <div className="md:flex-1">
          <div className="pb-16 md:pb-0">
            <h1
              dangerouslySetInnerHTML={{
                __html: t(
                  "{{ styling }}Sorry{{ stylingEnd }}, we could not make a price estimation.",
                  {
                    styling: '<span class="text-color_mywaybuy_two">',
                    stylingEnd: "</span>",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
              className="text-4xl md:text-6xl font-bold leading-tight md:leading-none text-center md:text-left"
            ></h1>
            <div className="text-base leading-normal text-color_mywaybuy_four ">
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    "This car does not fulfill the requirements for mywaybuy.<br><br> {{ styling }}Which cars does My Way Buy buy?{{ stylingEnd }}<br> We're interested in all car brands",
                    {
                      styling: "<strong>",
                      stylingEnd: "</strong>",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
                className="text-base leading-normal text-color_mywaybuy_four max-w-md mx-auto md:mx-0 py-6 text-center md:text-left"
              ></p>
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: 16,
                  marginVertical: 4,
                }}
              >
                {data.valuationLimits.maxKilometers && (
                  <li>
                    <p>
                      {t("km_limit", {
                        km: data.valuationLimits.maxKilometers.toLocaleString(),
                      })}
                    </p>
                  </li>
                )}
                {data.valuationLimits.maxAgeMonths && (
                  <li>
                    <p>
                      {t("year_limit", {
                        year: Math.round(
                          data.valuationLimits.maxAgeMonths / 12
                        ),
                      })}
                    </p>
                  </li>
                )}
              </ul>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Don't hesiate to contact us if there are any questions left<br><br> Greetings <br>{{ styling }}The Mywaybuy team{{ stylingEnd }}",
                    {
                      styling: "<strong>",
                      stylingEnd: "</strong>",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
                className="text-base leading-normal text-color_mywaybuy_four max-w-md mx-auto md:mx-0 py-6 text-center md:text-left"
              ></p>
            </div>
          </div>
        </div>
        <div className="max-w-lg w-full pl-6">
          <img
            src={require("../../assets/valuation/visual.svg")}
            alt="step 2"
            className="w-full"
          />
          <div className="relative">
            <a
              href="mailto:info@mywaybuy.be"
              className="flex cursor-pointer items-center transition-all duration-100 transform hover:shadow-xl hover:scale-95 shadow-2xl rounded-full relative md:absolute -top-14 md:top-auto md:bottom-1/4 md:left-0 lg:-left-10 bg-white"
              style={{ maxWidth: "24rem" }}
              // onClick={() => {
              //   //Track buttonclick
              //   let aTrack = analyticstracker();
              //   let trackingInfo = TAGMANAGER_BUTTON_ARGS({
              //     buttonName: "to-mail",
              //     buttonText: t("Send an e-mail"),
              //     buttonType: "button",
              //     buttonCategory: "outbound-link",
              //     buttonTarget: "mailto:info@mywaybuy.be",
              //     buttonPosition: "home-hero",
              //   });
              //   let event = {
              //     event: "button-click",
              //     info: JSON.parse(trackingInfo),
              //   };
              //   aTrack.trackEvent(event);
              // }}
            >
              <div className="w-10 top-[-30px] flex-none m-1">
                <img
                  src={require("../../assets/landing-page/seller-face.svg")}
                  alt={"My Way Buy seller face"}
                  className="w-full"
                />
              </div>
              <div className="flex-grow ml-2 mr-4">
                <p className="font-bold text-sm leading-none">
                  {t("Questions?")}
                </p>
                <p className="text-color_mywaybuy_four text-sm leading-relaxed">
                  {t("Send an e-mail")}
                </p>
              </div>
              <div
                className="w-10 h-10 flex-none rounded-full flex justify-center items-center m-1"
                style={{ backgroundColor: "rgb(255, 77, 86)" }}
              >
                <img
                  src={require("../../assets/landing-page/enveloppe.svg")}
                  alt={"Enveloppe icon"}
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
