import React from "react";
import { times } from "lodash";
import { useTranslation } from "react-i18next";

const LandingDifferentiation = () => {
  const { t } = useTranslation();

  const renderStar = (key) => {
    return (
      <img
        key={key}
        src={require("../../assets/landing-page/star.svg")}
        alt="star"
        className="w-3 sm:w-6 md:w-12"
      />
    );
  };

  return (
    <div className="py-16 my-10 mx-auto max-w-screen-xl">
      <h3 className="text-center uppercase text-color_mywaybuy_success font-medium tracking-wider">
        {t("The beste car sales site")}
      </h3>
      <h2 className="text-center text-4xl md:text-5xl max-w-2xl py-4 mx-auto font-bold leading-tight">
        {t("Why customers choose")}{" "}
        <span className="text-color_mywaybuy_success">My Way Buy</span>
      </h2>
      <div className="flex pt-14 max-w-5xl mx-auto">
        <div className="flex-1 md:pt-10 md:pb-20">
          <div className="text-center h-32 flex flex-col justify-between">
            <p className="font-bold text-primary text-xs sm:text-xl md:text-2xl pt-6">
              {t("Car dealer")}
            </p>
            <p className="font-bold text-primary text-lg sm:text-2xl md:text-3xl">
              9.000 €
            </p>
          </div>
          <div className="py-4">
            <p className="text-center pt-4 text-color_mywaybuy_four">
              {t("Security")}
            </p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(3, (index) => {
                return renderStar(`car-dealer-assurance-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Ease")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(3, (index) => {
                return renderStar(`car-dealer-comfort-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Price")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(2, (index) => {
                return renderStar(`car-dealer-price-star-${index}`);
              })}
            </div>
          </div>
        </div>
        <div className="flex-1 md:shadow-2xl rounded-3xl relative z-10 md:pt-10 md:pb-20">
          <div className="text-center h-32 flex flex-col justify-between">
            <img
              src={require("../../assets/landing-page/mywaybuy-logo-vertical.svg")}
              alt="logo my way buy"
              className="w-12 md:w-20 mx-auto"
            />
            <p className="font-bold text-primary text-xl sm:text-3xl md:text-4xl">
              10.000 €
            </p>
          </div>
          <div className="py-4">
            <p className="text-center pt-4 text-color_mywaybuy_four">
              {t("Security")}
            </p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(5, (index) => {
                return renderStar(`mywaybuy-assurance-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Ease")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(4, (index) => {
                return renderStar(`mywaybuy-comfort-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Price")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(4, (index) => {
                return renderStar(`mywaybuy-price-star-${index}`);
              })}
            </div>
          </div>
        </div>
        <div className="flex-1 md:pt-10 md:pb-20">
          <div className="text-center h-32 flex flex-col justify-between">
            <p className="font-bold text-primary text-xs sm:text-xl md:text-2xl pt-6">
              {t("Sell online yourself")}
            </p>
            <p className="font-bold text-primary text-lg sm:text-2xl md:text-3xl">
              10.500 €
            </p>
          </div>
          <div className="py-4">
            <p className="text-center pt-4 text-color_mywaybuy_four">
              {t("Security")}
            </p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(2, (index) => {
                return renderStar(`self-assurance-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Ease")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(2, (index) => {
                return renderStar(`self-comfort-star-${index}`);
              })}
            </div>
            <p className="text-center pt-4 text-color_mywaybuy_four">{t("Price")}</p>
            <div className="flex justify-center bg-color_mywaybuy_lightorange py-3">
              {times(5, (index) => {
                return renderStar(`self-price-star-${index}`);
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingDifferentiation;
