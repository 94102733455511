/*
These function provide an easy way to generate the data that has to be send with the analytics packages
*/
import { findLastIndex } from "lodash";
import analyticstracker from "analyticstracker";

export const getPreviousPageData = (data) => {
  const lastPageImpressionIndex = findLastIndex(data, [
    "event",
    "page-impression",
  ]);
  const previousPageData = data[lastPageImpressionIndex];
  return previousPageData;
};

export const getPreviousToolCompleteData = (data) => {
  const lastToolCompleteIndex = findLastIndex(data, ["event", "tool-complete"]);
  const previousToolCompleteData = data[lastToolCompleteIndex];
  return previousToolCompleteData;
};

export const TAGMANAGER_PAGE_ARGS = ({
  section = "",
  language = "",
  pageName = "",
  subSectionOne = "",
  previousPageName = "",
  previousPageUrl = "",
  previousPageFullUrl = "",
  previousPageType = "",
  previousPlatform = "",
  previousBrand = "",
}) => {
  const fullUrl = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const lma = searchParams.get("lma");
  const pageviewMode = lma ? "dealer" : "national";

  const hostname = window.location.hostname; //window.location.href;

  let brand;
  if (hostname.includes("audiapprovedplus")) {
    brand = "audi";
  } else if (hostname.includes("myway")) {
    brand = "myway";
  } else {
    brand = "unknown";
  }

  return JSON.stringify({
    pageName: pageName
      ? pageName
      : brand === "myway"
      ? `myway-buy/${section}`
      : `car-quotation/${section}`,
    section: section,
    subSectionOne: subSectionOne ? subSectionOne : "",
    subSectionTwo: "",
    pageUrl: fullUrl.split("?")[0],
    pageFullUrl: fullUrl,
    pageviewMode,
    pageType: section === "home" ? "homepage" : "form",
    platform: brand === "myway" ? "myway-buy" : "car-quotation",
    brand: brand, // audi or myway
    language: language,
    loginStatus: "not logged in",
    previousPageName: previousPageName ? previousPageName : "",
    previousPageUrl: previousPageUrl ? previousPageUrl : "",
    previousPageFullUrl: previousPageFullUrl ? previousPageFullUrl : "",
    previousPageType: previousPageType ? previousPageType : "",
    previousPlatform: previousPlatform ? previousPlatform : "",
    previousBrand: previousBrand ? previousBrand : "",
    pageVersion: "",
    author: "Autralis",
    dealerGroupID: lma || "",
    dealerGroupName: "",
    dealerID: "",
    dealerLocation: "",
    dealerName: "",
  });
};

export const TAGMANAGER_COMMERCE_ARGS = ({ carConfigCode = "" }) => {
  return JSON.stringify({
    // Following fields are required to send with event but not relevantin our case
    carBrand: "",
    carModel: "",
    referenceId: "",
    carConfigCode: carConfigCode,
    carEquipmentLine: "",
    carEngineType: "",
    carEnginePower: "",
    carGearboxType: "",
    carExteriorColor: "",
    carInteriorColor: "",
    carOptionsList: "",
    carPrice: "",
    carTotalPrice: "",
    carYear: "",
    carMileage: "",
  });
};

export const TAGMANAGER_TOOL_ARGS = ({
  toolName = "",
  toolType = "",
  toolStep = "",
  toolStepOption = "",
  toolStepNumber = "",
  requestType = "",
  inspectionPartTwo = false,
  inspectionPartThree = false,
  errorType = "",
  errorMessage = "",
}) => {
  const hostname = window.location.href; //window.location.hostname;
  const section =
    analyticstracker().pageInfo !== null
      ? analyticstracker().pageInfo.section
      : "";
  if (toolType === "") {
    toolType = section === "inspection" ? "inspection-form" : "request-form";
  }

  if (requestType === "") {
    requestType = section === "inspection" ? "inspection" : "car-quotation";
  }

  if (toolName === "") {
    if (hostname.includes("audiapprovedplus")) {
      toolName = "aap-car-quotation";
    } else if (hostname.includes("myway")) {
      if (toolType === "inspection-form") {
        toolName = "myway-buy-inspection";
      } else {
        toolName = "myway-car-quotation";
      }
    } else {
      toolName = "unknown";
    }
  }

  //could use a rewrite, as they kept adding parts to the inspection
  if (inspectionPartTwo) {
    toolName = toolName + "-2";
  } else if (inspectionPartThree) {
    toolName = toolName + "-3";
  }

  return JSON.stringify({
    toolType: toolType,
    toolName: toolName,
    toolStep: toolStep,
    toolStepNumber: toolStepNumber,
    toolStepOption: toolStepOption,
    leadID: "",
    requestType: requestType || "take-over",
    errorType: errorType || "",
    errorMessage: errorMessage || "",
  });
};

export const TAGMANAGER_BUTTON_ARGS = ({
  buttonName = "",
  buttonText = "",
  buttonType = "",
  buttonCategory = "",
  buttonTarget = "",
  buttonPosition = "",
}) => {
  return JSON.stringify({
    buttonType: buttonType || "button",
    buttonCategory: buttonCategory || "interaction",
    buttonName,
    buttonText,
    buttonTarget,
    buttonPosition,
  });
};
