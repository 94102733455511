import React, { useContext, useEffect, useState } from "react";
import DataContext from "../data/context";
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import { getDealerFromUrl } from "../utils";
import EmailConfirm from "./EmailConfirm";
import { useMutation } from "graphql-hooks";
import { LoadingIndicator } from "./indicators";
import { useTranslation } from "react-i18next";
import { useValuation } from "../hooks/useValuation";
import { fetchUserDataByCode } from "../data/session";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_PAGE_ARGS,
  TAGMANAGER_BUTTON_ARGS,
  getPreviousPageData,
} from "../tagManager";

import ValuationLinearBar from "./valuation/ValuationLinearBar";
import ValuationNextSteps from "./valuation/ValuationNextSteps";
import ValuationMessage from "./valuation/ValuationMessage";
import ValuationToInspection from "./valuation/ValuationToInspection";
import ValuationHelp from "./valuation/ValuationHelp";
import ValuationOutsideLimits from "./valuation/ValuationOutsideLimits";

export const confirmEmailMutation = `mutation ConfirmEmail($data: ConfirmEmailAddressType!) { 
        confirmEmail(data: $data) {
            ok,            
        }
    }`;

const Valuation = () => {
  const {
    inspection,
    inspectionStatus,
    fetchInspection,
    inspectionError,
    dealerKey,
    dealerConfig,
    setAuthenticated,
    setField,
  } = useContext(DataContext);
  const {
    valuation,
    loading: valuationLoading,
    hasValuation,
  } = useValuation(inspection);
  const { key } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [confirmMutation] = useMutation(confirmEmailMutation);

  const { t, i18n } = useTranslation();

  let history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  let confirmKey = undefined;
  let userKey = undefined;

  if (searchParams.has("c")) {
    confirmKey = searchParams.get("c");
  }

  if (searchParams.has("u")) {
    userKey = searchParams.get("u");
  }

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("page-impression");
  });

  const prevPageData = getPreviousPageData(window.dataLayer);

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    section: "valuation",
    language: i18n.language,
    previousBrand: prevPageData?.pageInfo?.brand
      ? prevPageData?.pageInfo?.brand
      : "",
    previousPageFullUrl: prevPageData?.pageInfo?.pageFullUrl
      ? prevPageData?.pageInfo?.pageFullUrl
      : "",

    previousPageName: prevPageData?.pageInfo?.pageName
      ? prevPageData?.pageInfo?.pageName
      : "",
    previousPageType: prevPageData?.pageInfo?.pageType
      ? prevPageData?.pageInfo?.pageType
      : "",
    previousPageUrl: prevPageData?.pageInfo?.pageUrl
      ? prevPageData?.pageInfo?.pageUrl
      : "",
    previousPlatform: prevPageData?.pageInfo?.platform
      ? prevPageData?.pageInfo?.platform
      : "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (inspection.key === undefined) {
      fetchInspection(key, getDealerFromUrl(window.location.pathname));
    }
  }, [key]);

  useEffect(() => {
    if (confirmKey && userKey) {
      handleConfirmation(confirmKey, userKey);
    } else {
      setLoading(false);
    }
  }, []);

  const handleConfirmation = (confirmationKey, userKey) => {
    confirmMutation({
      variables: {
        data: {
          inspectionKey: key,
          confirmationKey: confirmationKey,
          userKey: userKey,
        },
      },
    }).then((result) => {
      if (result.data && result.data.confirmEmail) {
        if (result.data.confirmEmail.ok) {
          fetchUserDataByCode(dealerKey, userKey).then((user) => {
            if (user) {
              setField("userId", user.id);
              setAuthenticated(user);
              setLoading(false);
            }
          });

          history.replace({
            ...location,
            search: "",
          });
        }
      }
    });
  };

  if (
    (inspection.key === undefined && !inspectionError) ||
    loading ||
    (valuationLoading && inspection.userId)
  ) {
    return (
      <div className="flex flex-1 items-center justify-center w-screen h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  if (inspectionError && inspection.key === undefined) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(
          window.location.pathname
        )}/no-inspection-found`}
      />
    );
  }

  if (
    inspectionStatus &&
    (inspectionStatus === "COMPLETED" || inspectionStatus === "SYNCED")
  ) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(window.location.pathname)}/inspection/${
          inspection.key
        }`}
      />
    );
  }

  if (inspection.userId === undefined || inspection.userId === null) {
    return <EmailConfirm />;
  }

  const QrCodeUrl = `${window.location.origin}/${getDealerFromUrl(
    window.location.pathname
  )}/inspection/${inspection.key}`;

  if (valuation.outsideLimits) {
    return <ValuationOutsideLimits/>
  }

  return (
    <div
      className="flex flex-1 md:justify-center my-12 font-mywaybuy"
      style={{ color: "rgb(0, 36, 59)" }}
    >
      <div
        className="w-full px-8 lg:px-0"
        style={{ maxWidth: 1024 }}
        data-tracking-event="page-impression"
        data-tracking-info={tagInfo}
      >
        <section className="mb-16 md:mb-20">
          <h1 className="font-bold text-30 md:text-36">
            {t("Estimation price of your vehicle")}
          </h1>
          {/* Horizontal bar with min and max valuation price */}
          <ValuationLinearBar valuation={valuation} />
          {/* Text box with more information about the valuation */}
          {dealerConfig.valuation && (
            <ValuationMessage hasValuation={hasValuation} />
          )}
        </section>
        <section className="mb-16 md:mb-20">
          <h2 className="font-bold text-30 md:text-36 mb-10">
            {t("Next steps")}
          </h2>
          {/* Steps which the user can take after getting a valuation */}
          <ValuationNextSteps />
        </section>
        <section className="pb-16 md:pb-20 border-b-2 border-color_mywaybuy_five mb-16 md:mb-20">
          {/* Text box with link and QR code to inspection */}
          <ValuationToInspection
            goToInspection={() => {
              //Track buttonclick
              let aTrack = analyticstracker();
              let trackingInfo = TAGMANAGER_BUTTON_ARGS({
                buttonName: "to-inspection",
                buttonText: t("Start inspection"),
                buttonType: "button-cta",
                buttonCategory: "inbound-link",
                buttonTarget: `/${getDealerFromUrl(
                  window.location.pathname
                )}/inspection/${inspection.key}`,
                buttonPosition: "valuation-cta",
              });
              let event = {
                event: "button-click",
                info: JSON.parse(trackingInfo),
              };
              aTrack.trackEvent(event);

              history.push(
                `/${getDealerFromUrl(window.location.pathname)}/inspection/${
                  inspection.key
                }`
              );
            }}
            qrCodeUrl={QrCodeUrl}
          />
        </section>
        <section>
          <ValuationHelp />
        </section>
      </div>
    </div>
  );

};
export default Valuation;
