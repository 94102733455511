import React from "react";
import { useTranslation } from "react-i18next";

const LandingSteps = () => {
  const { t } = useTranslation();

  return (
    <div className="py-10 md:py-14 my-16 px-4 mx-auto max-w-screen-xl">
      <h3 className="text-left uppercase text-color_mywaybuy_two font-medium tracking-wider">
        {t("4 steps")}
      </h3>
      <h2 className="text-left text-4xl md:text-5xl max-w-2xl py-4 font-bold leading-tight">
        {t("Sell your car in 4 easy steps")}
      </h2>
      <div className="flex items-center flex-col md:flex-row">
        <div className="flex-1 order-2 md:order-1">
          <div className="flex flex-col md:flex-row">
            <div className="flex-none flex justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_success mb-4">
              <p className="font-black text-white text-3xl md:text-4xl text-center m-0 p-0 leading-none">
                1
              </p>
            </div>
            <div className="md:pl-6">
              <h5 className="font-bold text-2xl pb-3">
                {t("Inspect your car yourself")}
              </h5>
              <p className="text-color_mywaybuy_four text-base leading-normal">
                {t(
                  "Use your smartphone to register the state and data about your car yourself in just a few fast steps! We guide you through the process."
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 order-1 md:order-2">
          <div className="flex justify-end relative left-auto sm:left-1/3 md:left-auto transform translate-x-1/3 translate-y-10 md:translate-x-0 md:translate-y-0">
            <div
              className="w-full md:pr-14 relative"
              style={{ maxWidth: "24rem" }}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgzIiBoZWlnaHQ9IjE0MyIgdmlld0JveD0iMCAwIDE4MyAxNDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDc2LjQyNTVMMzQuMTc1OSA0Ni42NDRMNjguODk4NyA4NS45Nzc3TDE1MS4yNTEgMEwxODIuNjIyIDI5Ljc4NTVMODYuOTkwNCAxMzQuODc2Qzg0Ljc4NjMgMTM3LjMwMSA4Mi4xMDAxIDEzOS4yMzUgNzkuMTA0OSAxNDAuNTU1Qzc2LjEwOTggMTQxLjg3NSA3Mi44NzIxIDE0Mi41NTEgNjkuNjAwNyAxNDIuNTM5QzY2LjMyOTMgMTQyLjUyNyA2My4wOTY3IDE0MS44MjcgNjAuMTExMyAxNDAuNDg1QzU3LjEyNTkgMTM5LjE0MyA1NC40NTM5IDEzNy4xODkgNTIuMjY3NyAxMzQuNzQ4TDAgNzYuNDI1NVoiIGZpbGw9IiNGRkJFMDAiLz4KPC9zdmc+Cg=="
                alt="checkmark"
                className="absolute top-0 right-0 w-2/5"
              />
              <img
                src={require("../../assets/landing-page/step-1.svg")}
                alt="step 1"
                className="w-full"
              />
              <div className="hidden lg:block bg-color_mywaybuy_error w-5 h-5 rounded absolute top-1/2 -left-10 transform rotate-45 z-0"></div>
              <div className="hidden sm:block bg-color_mywaybuy_warning w-4 h-4 rounded absolute bottom-4 right-4 transform rotate-45 z-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row">
        <div className="flex-1">
          <div className="flex justify-start relative -left-12 sm:left-1/3 md:left-auto transform translate-x-1/3 translate-y-10 md:translate-x-0 md:translate-y-0">
            <div className="max-w-sm w-full pl-14 relative">
              <img
                src={require("../../assets/landing-page/step-2.svg")}
                alt="step 2"
                className="w-full"
              />
              <div className="hidden sm:block bg-color_mywaybuy_warning w-3 h-3 rounded absolute top-1/2 left-0 transform rotate-45 z-0"></div>
              <div className="hidden sm:block bg-color_mywaybuy_error w-4 h-4 rounded absolute top-0 right-0 transform rotate-45 z-0"></div>
              <div className="hidden sm:block bg-color_mywaybuy_warning w-3 h-3 rounded absolute bottom-0 right-0 transform rotate-45 z-0"></div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col md:flex-row">
            <div className="pr-6 order-2 md:order-1">
              <h5 className="font-bold text-2xl pb-3 text-left md:text-right">
                {t("Receive an offer without obligation")}
              </h5>
              <p className="text-left md:text-right text-color_mywaybuy_four text-base leading-normal">
                {t(
                  "Receive an offer within 24 hours from one of our experts. Our offer is based on the current state of your car en the current data from the European Carmarket. That way we can guarantee you the best price."
                )}
              </p>
            </div>
            <div className="flex-none order-1 md:order-2 flex justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_success mb-4">
              <p className="font-black text-white text-3xl md:text-4xl text-center m-0 p-0 leading-none">
                2
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row">
        <div className="flex-1 order-2 md:order-1">
          <div className="flex flex-col md:flex-row">
            <div className="flex-none flex justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_success mb-4">
              <p className="font-black text-white text-3xl md:text-4xl text-center m-0 p-0 leading-none">
                3
              </p>
            </div>
            <div className="md:pl-6">
              <h5 className="font-bold text-2xl pb-3">
                {t(
                  "Visit one of our inspection points to confirm the take-over"
                )}
              </h5>
              <p className="text-color_mywaybuy_four text-base leading-normal">
                {t(
                  "Choose a date for a complete and independent car inpspection. Our expert confirms the state of the vehicle and validates the offer"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 order-1 md:order-2">
          <div className="flex justify-end relative left-7 sm:left-1/3 md:left-auto transform translate-x-1/3 translate-y-10 md:translate-x-0 md:translate-y-0">
            <div className="max-w-sm w-full md:pr-14 relative">
              <img
                src={require("../../assets/landing-page/step-3.svg")}
                alt="step 3"
                className="w-full"
              />
              <div className="hidden lg:block bg-color_mywaybuy_warning w-3 h-3 rounded absolute top-1/2 -left-16 transform rotate-45 z-0"></div>
              <div className="hidden sm:block bg-color_mywaybuy_error w-4 h-4 rounded absolute -bottom-10 left-2/3 transform rotate-45 z-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row">
        <div className="flex-1">
          <div className="flex justify-start relative -left-12 sm:left-1/3 md:left-auto transform translate-x-1/3 translate-y-10 md:translate-x-0 md:translate-y-0">
            <div className="max-w-sm w-full pl-14 relative">
              <img
                src={require("../../assets/landing-page/step-4.svg")}
                alt="step 4"
                className="w-full"
              />
              <div className="hidden sm:block bg-color_mywaybuy_warning w-4 h-4 rounded absolute top-1/2 left-2 transform rotate-45 z-0"></div>
              <div className="hidden sm:block bg-color_mywaybuy_error w-5 h-5 rounded absolute top-0 right-0 transform rotate-45 z-0"></div>{" "}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col md:flex-row">
            <div className="pr-6 order-2 md:order-1">
              <h5 className="font-bold text-2xl pb-3 text-left md:text-right">
                {t("Receive your payment within 24 hours")}
              </h5>
              <p className="text-left md:text-right text-color_mywaybuy_four text-base leading-normal">
                {t(
                  "After confirming the takeover of your vehicle, we guarantee that you will receive the money safely in your bank account within 24 hours."
                )}
              </p>
            </div>
            <div className="flex-none order-1 md:order-2 flex justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_success mb-4">
              <p className="font-black text-white text-3xl md:text-4xl text-center m-0 p-0 leading-none">
                4
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSteps;
