import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getDealerFromUrl } from "../../utils";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

import LandingButton from "./LandingButton";

const LandingUniqueSellingPoints = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="relative py-10 md:py-14 px-4 max-w-screen-xl mx-auto">
      <h3 className="text-center uppercase text-color_mywaybuy_two font-medium tracking-wider mb-0 leading-normal">
        {t("No test drives. No negotiations. No worries.")}
      </h3>
      <h2
        dangerouslySetInnerHTML={{
          __html: t(
            "With MyWay Buy, {{ styling }}selling your car{{ stylingEnd }} is&#58;",
            {
              styling: '<span class="text-color_mywaybuy_two">',
              stylingEnd: "</span>",
              interpolation: { escapeValue: false },
            }
          ),
        }}
        className="text-center text-4xl md:text-5xl max-w-2xl py-4 mx-auto font-bold leading-tight md:leading-none"
      ></h2>
      <div className="flex flex-col md:flex-row mt-16 md:mt-32 mb-12 md:mb-24 relative">
        <img
          src={
            "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTA5IiBoZWlnaHQ9IjE0NSIgdmlld0JveD0iMCAwIDkwOSAxNDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yLjUgOTcuMDQ1NkM0Ny4zMzMzIDEyMy4wNDYgMTUyLjkgMTU5LjQ0NiAyMTYuNSA5Ny4wNDU2QzI5NiAxOS4wNDU2IDcxIC0zMy45NTQ0IDEwNyAzMS41NDU2QzE0MyA5Ny4wNDU2IDM1Ny41IC04LjQ1NDU5IDQ5MS41IDg4LjU0NTRDNjI1LjUgMTg1LjU0NSA4MjQgMTMxLjA0NSA5MDYuNSA4OC41NDU0IiBzdHJva2U9IiNGRjRENTYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtZGFzaGFycmF5PSI4IDgiLz4KPC9zdmc+Cg=="
          }
          alt={"dashed line"}
          className="hidden md:block absolute -top-14 z-0 long-dashed-line left-1/2 transform -translate-x-1/2"
          style={{ width: "70%" }}
        />
        <div className="flex-1 md:pr-16 pt-4 pb-14 bg-white">
          <div className="flex md:block items-center pb-6 md:pb-8">
            <div className="flex flex-shrink justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_two mx-auto relative md:-top-8">
              <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLDE1LjRsLTMuOCwyLjNsMS00LjNsLTMuMy0yLjlsNC40LTAuNGwxLjctNGwxLjcsNGw0LjQsMC40bC0zLjMsMi45bDEsNC4zIE0yMiw5LjJsLTcuMi0wLjZMMTIsMkw5LjIsOC42CglMMiw5LjJMNy40LDE0bC0xLjYsN2w2LjItMy43bDYuMiwzLjdsLTEuNi03TDIyLDkuMnoiLz4KPC9zdmc+Cg=="
                alt="icon start"
                className="w-full p-2"
              />
            </div>
            <h4 className="flex-grow pl-4 md:pl-0 text-left md:text-center font-bold text-2xl md:text-3xl">
              {t("Economical")}
            </h4>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "Our experts calculate for free {{styling}}the best price for your car{{stylingEnd}} based on European market data, years of expertise in the automotive market and the data you forward. As a result, you get {{styling}}within 24 hours a guaranteed offer{{stylingEnd}}&#58; no negotiations or disappointments afterwards.",
                {
                  styling: "<strong>",
                  stylingEnd: "</strong>",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
            className="text-left md:text-center text-color_mywaybuy_four leading-normal"
          ></p>
        </div>
        <div className="flex-1 md:px-8 pt-0 pb-14 md:shadow-2xl rounded-3xl bg-white relative z-10">
          <div className="flex md:block items-center pb-6 md:pb-8">
            <div className="flex flex-shrink justify-center items-center rounded-xl w-12 h-12 md:w-16 md:h-16 bg-color_mywaybuy_two mx-auto relative md:-top-8">
              <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLDE1LjRsLTMuOCwyLjNsMS00LjNsLTMuMy0yLjlsNC40LTAuNGwxLjctNGwxLjcsNGw0LjQsMC40bC0zLjMsMi45bDEsNC4zIE0yMiw5LjJsLTcuMi0wLjZMMTIsMkw5LjIsOC42CglMMiw5LjJMNy40LDE0bC0xLjYsN2w2LjItMy43bDYuMiwzLjdsLTEuNi03TDIyLDkuMnoiLz4KPC9zdmc+Cg=="
                alt="icon start"
                className="w-full p-2 md:p-3"
              />
            </div>
            <h4 className="flex-grow pl-4 md:pl-0 text-left md:text-center font-bold text-2xl md:text-3xl">
              {t("Safe")}
            </h4>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "You are helped by professionals&#58; My Way Buy benefits from the many years of expertise of D'Ieteren Automotive. During the inspection our experts guarantee {{ styling }}a fair and transparent inspection of your car{{ stylingEnd }}. After the sale, the money is guaranteed in your account within 24 hours.",
                {
                  styling: "<strong>",
                  stylingEnd: "</strong>",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
            className="text-left md:text-center text-color_mywaybuy_four leading-normal"
          ></p>
        </div>{" "}
        <div className="flex-1 md:pl-16 pt-4 pb-14 bg-white">
          <div className="flex md:block items-center pb-6 md:pb-8">
            <div className="flex flex-shrink justify-center items-center rounded-xl w-12 h-12 md:w-14 md:h-14 bg-color_mywaybuy_two mx-auto relative md:-top-8">
              <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLDE1LjRsLTMuOCwyLjNsMS00LjNsLTMuMy0yLjlsNC40LTAuNGwxLjctNGwxLjcsNGw0LjQsMC40bC0zLjMsMi45bDEsNC4zIE0yMiw5LjJsLTcuMi0wLjZMMTIsMkw5LjIsOC42CglMMiw5LjJMNy40LDE0bC0xLjYsN2w2LjItMy43bDYuMiwzLjdsLTEuNi03TDIyLDkuMnoiLz4KPC9zdmc+Cg=="
                alt="icon start"
                className="w-full p-2"
              />
            </div>
            <h4 className="flex-grow pl-4 md:pl-0 text-left md:text-center font-bold text-2xl md:text-3xl">
              {t("Simple")}
            </h4>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "With My Way Buy you don't lose time advertising the car and negotiating with buyers. {{ styling }}Fill in the detail of your car, receive your offer, visit one of our inspection points for a take-over and receive your payment.{{ stylingEnd }} Selling your car has never been so easy.",
                {
                  styling: "<strong>",
                  stylingEnd: "</strong>",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
            className="text-left md:text-center text-color_mywaybuy_four leading-normal"
          ></p>
        </div>
      </div>
      <div className="text-center">
        <LandingButton
          text={t("Receive your free offer")}
          onClick={() => {
            //Track buttonclick
            let aTrack = analyticstracker();
            let trackingInfo = TAGMANAGER_BUTTON_ARGS({
              buttonName: "to-car-quotation",
              buttonText: t("Receive your free offer"),
              buttonType: "button-cta",
              buttonCategory: "inbound-link",
              buttonTarget: `/${getDealerFromUrl(
                window.location.pathname
              )}/inspection`,
              buttonPosition: "home-usp-block",
            });
            let event = {
              event: "button-click",
              info: JSON.parse(trackingInfo),
            };
            aTrack.trackEvent(event);

            history.push(
              `/${getDealerFromUrl(window.location.pathname)}/inspection`
            );
          }}
        />
      </div>
      <div className="hidden sm:block bg-color_mywaybuy_warning w-10 h-10 rounded-lg absolute top-5 left-0 transform rotate-12 z-0"></div>
      <div className="hidden sm:block bg-color_mywaybuy_error w-8 h-8 rounded absolute bottom-0 right-10 transform rotate-45 z-0"></div>
    </div>
  );
};

export default LandingUniqueSellingPoints;
