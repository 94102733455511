import React, {useState} from "react";

//Shadows are implemented manually here as custom tailwind does not seem to work
const LandingButton = ({ onClick, text, additionalClasses }) => {
  const [hoverShadow, setHoverShadow] = useState("0 20px 40px 0 rgba(147, 213, 0, 0.3)")


  return (
    <button
      onClick={onClick}
      className={`${additionalClasses} cursor-pointer block md:inline-block w-full md:w-auto text-center text-white text-lg font-bold px-8 py-4 md:py-3 bg-color_mywaybuy_darkgreen rounded-lg transition duration-100 transform  hover:scale-95`}
      style={{boxShadow: hoverShadow}}
      onMouseEnter={() => setHoverShadow("0 10px 20px 0 rgba(147, 213, 0, 0.5)")}
      onMouseLeave={() => setHoverShadow("0 20px 40px 0 rgba(147, 213, 0, 0.3)")}
    >
      {text}
    </button>
  ); 
};

export default LandingButton;
