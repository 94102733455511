import React from "react";
import Pictures from "./wizardsteps/Pictures";
import Damages from "./wizardsteps/Damages";
import GeneralState from "./wizardsteps/GeneralState";
import Features from "./wizardsteps/Features";
import Checks from "./wizardsteps/Checks";
import i18n from '../../i18n';
import VehicleIdentification from "./wizardsteps/VehicleIdentification";

export const getTranslatedSteps = () => {
    return [
        {
            title: i18n.t("Provide your VIN number"),
            description: i18n.t("Your VIN number is a crucial piece of information for us to know exactly which car you are talking about. Without it it's impossible for us to determine the value of your car."),
            icon: 'far fa-car',
            actionName: i18n.t('Add VIN number'),
            component: <VehicleIdentification/>
        },
        {
            title: i18n.t("Take pictures of your car"),
            // description: i18n.t("Upload photos of your car so we’ll get a good understanding of how your car looks"),
            description: i18n.t("We always recommend to take pictures in natural daylight, on a spacious parking area. Empty the car so that the seats and the inside of trunk are visible. For each view you’ll find an example picture on the right. Note that we’ll be able to offer you the best takeover price if you provide us with clear and high quality pictures."),
            icon: 'far fa-camera',
            actionName: i18n.t('Take pictures'),
            component: <Pictures/>,
        },

        {
            title: i18n.t("The general state"),
            description: i18n.t("Indicate what state your car is in. These factors below help determine the value of your car. The more information you can provide, the more accurate the valuation."),
            icon: 'fal fa-thumbs-up',
            actionName: i18n.t('General state of the car'),
            component: <GeneralState/>,
        },
        {
            title: i18n.t("Options"),
            description: i18n.t("Options on a car determine it's worth significantly. Check thoroughly which options are available on your car. This way we'll be able to offer you a correct price."),
            icon: 'fal fa-tasks',
            actionName: i18n.t('Options'),
            component: <Features/>,
        },
        {
            title: i18n.t("Damages on your car"),
            // description: i18n.t("Is your car damaged in any way? Upload a picture indicating the damage with your finger."),
            description: i18n.t("Select the damage locations on the car below and add a picture. To clearly demonstrate the size of the damage, hold an object up next to it as reference. E.g. car keys or board documents."),
            icon: 'fal fa-car-crash',
            actionName: i18n.t('Determine damage'),
            component: <Damages/>,
            badgeCounter: (inspection) => inspection.damages.length
        },
        {
            title: i18n.t("Extras"),
            description: i18n.t("Please answer these questions correctly. These factors determine the worth of you car. With correct information, we'll be able to do a detailed car valuation. "),
            icon: 'fal fa-question',
            actionName: i18n.t('Extras'),
            component: <Checks/>,
        }
    ];
};


export const wizardSteps = getTranslatedSteps();
