import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm from "./contact/ContactForm";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-1 justify-center font-mywaybuy text-color_mywaybuy_one"
      data-tracking-event="page-impression"
    >
      <div className="w-full max-w-screen-lg mx-auto">
        <div className="py-12 px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-5">
            {t("Do you have a question")}?
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                "Please fill in the contact form and {{ styling }}we will contact you{{ stylingEnd }}",
                {
                  styling: "<span class='font-bold'>",
                  stylingEnd: "</span>",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
            className="rounded-xl bg-color_mywaybuy_five text-color_mywaybuy_four px-5 py-5 mb-10"
          ></div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
