import React from "react";
import { formatPrice } from "../../utils";

const ValuationLinearBar = ({ valuation }) => {

  return (
    <div className="text-color_mywaybuy_two my-10">
      <div className="relative w-full">
        {valuation?.min !== 0 && (
          <div className="absolute" style={{ left: "20%" }}>
            <div
              className="bg-color_mywaybuy_two text-white font-bold text-16 md:text-24 px-3 py-2 whitespace-no-wrap"
              style={{
                transform: "translate(-50%)",
                borderRadius: 8,
              }}
            >
              
              {formatPrice(valuation.min)}
            </div>
            <div
              className="w-0 h-0"
              style={{
                borderTop: "10px solid #419131",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                transform: "translate(-50%)",
              }}
            ></div>
          </div>
        )}
        {valuation.max !== 0 && (
          <div className="absolute" style={{ left: "80%" }}>
            <div
              className="bg-color_mywaybuy_two text-white font-bold text-16 md:text-24 px-3 py-2 whitespace-no-wrap"
              style={{
                transform: "translate(-50%)",
                borderRadius: 8,
              }}
            >
              {formatPrice(valuation.max)}
            </div>
            <div
              className="w-0 h-0"
              style={{
                borderTop: "10px solid #419131",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                transform: "translate(-50%)",
              }}
            ></div>
          </div>
        )}
        <div className="pt-16 md:pt-20 flex items-center">
          <div className="flex h-3 md:h-4 w-full bg-color_mywaybuy_two bg-opacity-25 rounded-full">
            <div
              className="bg-color_mywaybuy_two w-full bg-opacity-75"
              style={{ marginLeft: "20%", marginRight: "20%" }}
            ></div>
          </div>
          <div
            className="absolute w-8 md:w-12 h-8 md:h-12 rounded-full bg-white border-color_mywaybuy_two border-4"
            style={{ left: "20%", transform: "translate(-50%)" }}
          ></div>
          <div
            className="absolute w-8 md:w-12 h-8 md:h-12 rounded-full bg-white border-color_mywaybuy_two border-4"
            style={{ left: "80%", transform: "translate(-50%)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ValuationLinearBar;
