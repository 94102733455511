import React, { useContext, useEffect, useState } from "react";
import { TextField } from "../input";
import DataContext from "../../data/context";
import { ClientContext, useMutation } from "graphql-hooks";
import SelectBox from "../input/SelectBox";
import { hasValue, isValidEmail } from "../../validators";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../indicators";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ContentPopup from "../legal/ContentPopup";
import TagManager from "react-gtm-module";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../tagManager";

export const contactInformationMutation = `mutation SetContactInformation($key: String!, $data: ContactInformationInputType!) { 
        setContactInformation(key: $key data: $data) {
            ok,           
        }
    }`;

export const inspectionConfirmMutation = `mutation InspectionConfirm($key: String!) { 
        inspectionConfirm(key: $key) {
            ok,  
            error,   
            takeOver {
              state
              proposed
              proposedAmount
              accepted
              acceptedAmount
              documentUrl
              expertId     
            }            
        }
    }`;

const InspectionContact = ({ onCompleted }) => {
  const {
    inspection,
    setField,
    setPrevWizardStep,
    takeOverSetHandler,
    setInspectionPending,
    dealerConfig,
  } = useContext(DataContext);
  const [setContactInformation, { loading: contactInformationLoading }] =
    useMutation(contactInformationMutation);
  const [confirmInspection, { loading: confirmInspectionLoading }] =
    useMutation(inspectionConfirmMutation);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const client = useContext(ClientContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loader, setLoader] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [tagArgsSubmit, setTagArgsSubmit] = useState(
    TAGMANAGER_TOOL_ARGS({
      event: "submit",
      toolStep: "identification",
      toolStepNumber: "7",
      toolType: "inspection-form",
      requestType: "inspection",
      //inspectionPartTwo: true
    })
  );
  const loading =
    loader || confirmInspectionLoading || contactInformationLoading;

  const hostname = window.location.hostname; //window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "identification",
    toolStepNumber: "7",
    toolType: "inspection-form",
    requestType: "inspection",
    //inspectionPartTwo: true
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });

  const validate = () => {
    let errors = {};

    if (!hasValue(inspection.firstName)) {
      errors["firstName"] = t("Please fill out your first name");
    }

    if (!hasValue(inspection.lastName)) {
      errors["lastName"] = t("Please fill out your last name");
    }

    if (!hasValue(inspection.email)) {
      errors["email"] = t("Please enter your email address");
    } else if (!isValidEmail(inspection.email)) {
      errors["email"] = t("Invalid email address");
    }

    if (!hasValue(inspection.telephone)) {
      errors["telephone"] = t("Please fill out your telephone number");
    }

    if (!hasValue(inspection.street)) {
      errors["street"] = t("Please fill out your street");
    }

    if (!hasValue(inspection.number)) {
      errors["number"] = t("Please fill out your house number");
    }

    if (!hasValue(inspection.postalCode)) {
      errors["postalCode"] = t("Please fill out your postal code");
    }

    if (!hasValue(inspection.city)) {
      errors["city"] = t("Please fill out the city you live in");
    }

    if (dealerConfig.formsPrivacyTickBox && !privacyAccepted) {
      errors["privacy"] = t("Please accept the privacy conditions");
    }
    Object.values(errors).map((message) => {
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "identification",
        toolStepNumber: "7",
        errorType: "user-error",
        errorMessage: message,
        toolType: "inspection-form",
        requestType: "inspection",
        //inspectionPartTwo: true
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    });
    setErrors(errors);

    return Object.keys(errors).length <= 0;
  };

  const handleContactInformation = (e) => {
    if (validate()) {
      // for tracking
      let aTrack = analyticstracker();
      aTrack.trackInteraction(e);

      setLoader(true);
      setContactInformation({
        variables: {
          key: inspection.key,
          data: {
            firstName: inspection.firstName,
            lastName: inspection.lastName,
            email: inspection.email,
            street: inspection.street,
            number: inspection.number,
            postalCode: inspection.postalCode,
            city: inspection.city,
            telephone: inspection.telephone,
            comment: inspection.comment,
          },
        },
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.setContactInformation &&
          result.data.setContactInformation.ok
        ) {
          handleInspectionConfirmation();
        } else {
          setLoader(false);
        }
      });
    }
  };

  const handleInspectionConfirmation = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("valuation");
    client.setHeader("Captcha", token);

    confirmInspection({
      variables: {
        key: inspection.key,
      },
    }).then((result) => {
      if (
        result &&
        result.data &&
        result.data.inspectionConfirm &&
        result.data.inspectionConfirm.ok
      ) {
        takeOverSetHandler(
          inspection.key,
          result.data.inspectionConfirm.takeOver
        );
        setInspectionPending(false);

        // Only track tool-complete for non-myway selfchecks
        // MyWay tool complete is done on last success page instead (take-over/status/initial.js)
        if (!hostname.includes("myway")) {
          let trackingEvent = "tool-complete";
          let trackingInfo = TAGMANAGER_TOOL_ARGS({
            event: "complete",
            toolStep: "thank-you",
            toolStepNumber: "8",
            toolType: "inspection-form",
            requestType: "inspection",
            //inspectionPartTwo: true
          });
          let trackingCommerce = TAGMANAGER_COMMERCE_ARGS({
            carConfigCode: inspection.key,
          });

          let aTrack = analyticstracker();
          let event = {
            event: trackingEvent,
            info: JSON.parse(trackingInfo),
            commerce: JSON.parse(trackingCommerce),
          };
          aTrack.trackEvent(event);
        }
        onCompleted();
      } else {
        setInspectionPending(false);
        onCompleted();
      }
      setLoader(false);
    });
  };

  return (
    <div
      className="w-full md:flex md:justify-center"
      style={{ maxWidth: 1024 }}
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="md:flex md:justify-center">
        <div className="flex flex-1 flex-col bg-white md:mr-24">
          <div className="flex flex-col bg-color_one text-white items-center justify-center text-center pl-8 pr-8 md:rounded-lg md:mt-12 pt-12 pb-12">
            <span className="text-white text-24 font-bold mb-5">
              {t("Complete your information")}
            </span>

            <p className="text-16 leading-5">
              {t(
                `We'll get started with your details and email you a takeover quote within 72 hours.`
              )}
            </p>
          </div>
        </div>

        <div className="flex flex-1 flex-col pl-10 pr-10 mt-12">
          <div className="text-color_one text-24 font-bold">
            {t("Complete your information")}
          </div>

          <div className="text-color_three mb-8 mt-8 leading-6">
            <p>
              {t(
                "To make sure you're notified, we ask you to supply us with your details. We will contact you shortly with a takeover proposal."
              )}
            </p>
          </div>

          <div className="mb-6">
            <TextField
              label={t("First name")}
              placeholder={t("Your first name")}
              value={inspection.firstName}
              onChange={(value) => setField("firstName", value)}
              error={errors["firstName"]}
            />
          </div>

          <div className="mb-6">
            <TextField
              label={t("Last name")}
              placeholder={t("Your last name")}
              value={inspection.lastName}
              onChange={(value) => setField("lastName", value)}
              error={errors["lastName"]}
            />
          </div>

          <div className="mb-6">
            <TextField
              label={t("Email address")}
              placeholder={t("Your email address")}
              value={inspection.email}
              onChange={(value) => setField("email", value)}
              error={errors["email"]}
              readOnly={true}
            />
          </div>

          <div className="mb-6">
            <TextField
              label={t("Telephone")}
              placeholder={t("Your telephone number")}
              value={inspection.telephone}
              onChange={(value) => setField("telephone", value)}
              error={errors["telephone"]}
            />
          </div>

          <hr className="solid border-color-five mt-6 mb-6" />

          <div className="mb-6 flex flex">
            <div className="flex flex-col flex-1 mr-2">
              <TextField
                label={t("Street and number")}
                placeholder={t("Street")}
                value={inspection.street}
                onChange={(value) => setField("street", value)}
                error={errors["street"]}
              />
            </div>

            <div className="" style={{ width: 80 }}>
              <TextField
                placeholder={t("nr")}
                value={inspection.number}
                onChange={(value) => setField("number", value)}
                error={errors["number"]}
              />
            </div>
          </div>

          <div className="mb-6">
            <TextField
              label={t("Postal code")}
              placeholder={t("Postal code")}
              value={inspection.postalCode}
              onChange={(value) => setField("postalCode", value)}
              error={errors["postalCode"]}
            />
          </div>

          <div className="mb-6">
            <TextField
              label={t("City")}
              placeholder={t("City")}
              value={inspection.city}
              onChange={(value) => setField("city", value)}
              error={errors["city"]}
            />
          </div>

          <hr className="solid border-color-five mt-6 mb-6" />

          {dealerConfig.formsPrivacyTickBox && (
            <div className="flex items-center mb-5 tracking-wide">
              <SelectBox
                description=""
                selected={privacyAccepted}
                onChange={(value) => setPrivacyAccepted(value)}
              />
              <div
                className={`${
                  errors["privacy"] ? "text-color_error" : "text-color_three"
                } text-14  ml-5`}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "I agree to the {{styling}} privacy terms {{stylingEnd}}",
                    {
                      styling: '<span class="underline cursor-pointer">',
                      stylingEnd: "</span />",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
                onClick={() => setPrivacyModalVisible(true)}
              ></div>
            </div>
          )}

          <div className="flex flex-col md:flex-row-reverse justify-center items-center mb-10">
            <div
              className="flex rounded-full uppercase bg-color_one text-white items-center justify-center text-center font-bold text-14 md:text-12 cursor-pointer pl-4 pr-4 tracking-wide w-full md:w-3/5"
              style={{ minHeight: 45, minWidth: 280 }}
              data-tracking-event="tool-submit"
              data-tracking-info={tagArgsSubmit}
              data-tracking-commerce={tagArgsCommerce}
              onClick={(e) => handleContactInformation(e)}
            >
              {loading && (
                <div className="flex items-center">
                  <LoadingIndicator extraSmall color="white" />
                </div>
              )}
              {!loading && <div>{t("Send data")}</div>}
            </div>

            <div
              className="text-color_one mr-0 md:mr-10 cursor-pointer mt-6 md:mt-0 uppercase"
              onClick={setPrevWizardStep}
            >
              <i className="fal fa-chevron-left font-bold mr-2" />{" "}
              {t("Previous")}
            </div>
          </div>
        </div>
      </div>

      {privacyModalVisible && (
        <ContentPopup
          type="privacy_policy"
          onClose={() => setPrivacyModalVisible(false)}
        />
      )}
    </div>
  );
};

export default InspectionContact;
