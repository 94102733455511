import React, { useContext, useEffect, useState } from "react";
import "./styles/custom.css";
import "./styles/icomoon/style.css";
import Home from "./components/Home";
import Valuation from "./components/Valuation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DataContext from "./data/context";
import Inspection from "./components/inspection/Inspection";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Page404 from "./components/Page404";

import { useTranslation } from "react-i18next";
import { ClientContext } from "graphql-hooks";
import Splash from "./components/Splash";
import Privacy from "./components/legal/Privacy";
import Login from "./components/acount/Login";
import Register from "./components/acount/Register";
import ForgotPassword from "./components/acount/ForgotPassword";
import ResetPassword from "./components/acount/ResetPassword";
import PrivateDealerRoute from "./navigation/PrivateDealerRoute";
import Account from "./components/acount/Account";
import DealerRoute from "./navigation/DealerRoute";
import LoadingIndicator from "./components/indicators/LoadingIndicator";
import i18next from "i18next";
import { Helmet } from "react-helmet";
import Content from "./components/legal/components/Content";
import { includes, map } from "lodash";
import CookieBanner from "./components/legal/CookieBanner";
import { validate as uuidValidate } from "uuid";
import Cookies from "js-cookie";
import TermsAndConditions from "./components/legal/TermsAndConditions";
import TestCookieBanner from "./TestCookieBanner";
import TagManager from "react-gtm-module";
import LandingPage from "./components/LandingPage";
import Contact from "./components/Contact";

export const availableLanguages = ["nl", "fr"];

//Cookies.set('lang', 'nl')

const App = () => {
  const {
    dealer,
    dealerKey,
    dealerConfig,
    fetchDealer,
    initialDataLoaded,
    initialDataError,
    setField,
    cookiesAccepted,
  } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const client = useContext(ClientContext);

  // if a user indicated he does not want analytics cookies remove the cookies initialized by react-ga and hotjar
  if (Cookies.get("GDPR_ANALYTICS") === "false") {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("_hjid");
    Cookies.remove("_hjFirstSeen");
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("guid")) {
      const guid = searchParams.get("guid");
      if (uuidValidate(guid)) {
        setField("guid", guid);
        const cleanedUrl = window.location.search.replace(`guid=${guid}`, "");
        window.history.replaceState(null, null, cleanedUrl);
      }
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("guid")) {
      const guid = searchParams.get("guid");
      if (uuidValidate(guid)) {
        setField("guid", guid);
        const cleanedUrl = window.location.search.replace(`guid=${guid}`, "");
        window.history.replaceState(null, null, cleanedUrl);
      }
    }

    //This is used to eventually skip the "make" step as it's prefilled by the URL
    const make = searchParams.get("make");
    if (make) {
      setField("make", make);
    }

    //This is used to eventually skip the "model" step as it's prefilled by the URL
    const model = searchParams.get("model");
    if (model) {
      setField("model", model);
    }
  }, []);

  useEffect(() => {
    if (initialDataError) {
      window.location = "https://b2c.selfcheck.io/autralis";
      return null;
    }
  }, [initialDataError]);

  const getCurrentLanguage = () => {
    let lng =
      i18n.language && i18next.language !== ""
        ? i18n.language.substring(0, 2)
        : "en";
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("language")) {
      const tmp = searchParams.get("language");
      lng = includes(availableLanguages, tmp) ? tmp : lng;
    }
    return lng;
  };

  useEffect(() => {
    if (dealer === undefined) {
      let lng = getCurrentLanguage();
      client.setHeader("Accept-Language", lng);
      i18n.changeLanguage(lng);

      fetchDealer(dealerKey);
    }
  }, []);

  const getBrandingCssUrl = () => {
    return window.location.hostname !== "localhost"
      ? `${window.location.origin}/branding/${dealerKey}.css`
      : `/branding/${dealerKey}.css`;
  };

  useEffect(() => {
    if (initialDataLoaded) {
      // //initialize google tag manager
      // if (dealerConfig?.googleTagManagerContainerId) {
      //   const tagManagerArgs = {
      //     gtmId: dealerConfig.googleTagManagerContainerId,
      //   };
      //   TagManager.initialize(tagManagerArgs);
      // }

      //show a loading screen momentarily
      const timer = setTimeout(() => {
        setLoading(false);
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [initialDataLoaded]);

  if (!initialDataLoaded || loading) {
    return <LoadingIndicator />;
  }

  const getFavoriteIconUrl = () => {
    if (dealerConfig.favIcon && dealerConfig.favIcon !== "") {
      return `${window.location.origin}/branding/fav-${dealerKey}.png`;
    }
    return "/favicon.png";
  };

  const getPageTitle = () => {
    if (dealerConfig.pageTitle && dealerConfig.pageTitle !== "") {
      return dealerConfig.pageTitle;
    }
    return "Selfcheck.io";
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{getPageTitle()}</title>
        <style type="text/css">{`.async-hide { opacity: 0 !important}`}</style>
        <script
          type="text/javascript"
          src="//nexus.ensighten.com/dieteren/myway_prod/Bootstrap.js "
        ></script>

        <script>
          {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
        h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
        (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,
        {'${dealerConfig?.googleTagManagerContainerId}':true});
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${dealerConfig?.googleTagManagerContainerId}');`}
        </script>
        {map(dealerConfig.htmlHeadScripts, (url) => {
          return <script type="text/javascript" src={url}></script>;
        })}
        <link href={dealerConfig.fontUrl} rel="stylesheet" type="text/css" />
        <link
          href={getBrandingCssUrl()}
          rel="stylesheet"
          type="text/css"
          async={true}
        />
        <link rel="icon" href={getFavoriteIconUrl()} />
        <script>{dealerConfig.htmlHeadScript}</script>
        <noscript>{dealerConfig.htmlHeadNoScript}</noscript>
      </Helmet>
      <Router>
        <Header />

        <Switch>
          <DealerRoute exact path="/:dealer">
            <Splash />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/landing">
            <LandingPage />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/contact">
            <Contact />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/inspection">
            <Home />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/valuation/:key">
            <Valuation />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/inspection/:key">
            <Inspection />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/privacy">
            <Privacy />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/legal">
            <TermsAndConditions />
          </DealerRoute>
          <DealerRoute exact path="/:dealer/login">
            <div className="flex flex-1  md:justify-center md:mb-12 md:mt-12">
              <div className="flex justify-center" style={{ maxWidth: 1024 }}>
                <Login />
              </div>
            </div>
          </DealerRoute>
          <DealerRoute exact path="/:dealer/register">
            <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
              <div className="flex justify-center" style={{ maxWidth: 1024 }}>
                <Register />
              </div>
            </div>
          </DealerRoute>
          <DealerRoute exact path="/:dealer/forgot-password">
            <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
              <div className="flex  justify-center" style={{ maxWidth: 1024 }}>
                <ForgotPassword />
              </div>
            </div>
          </DealerRoute>
          <DealerRoute exact path="/:dealer/forgot-password/:resetCode">
            <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
              <div className="flex justify-center" style={{ maxWidth: 1024 }}>
                <ResetPassword />
              </div>
            </div>
          </DealerRoute>
          <PrivateDealerRoute exact path="/:dealer/account">
            <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
              <div
                className="md:flex md:justify-center"
                style={{ maxWidth: 1024 }}
              >
                <Account />
              </div>
            </div>
          </PrivateDealerRoute>

          <DealerRoute exact path="/:dealer/convention">
            <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
              <div
                className="flex flex-1 md:justify-center"
                style={{ maxWidth: 1024 }}
              >
                <Content type="convention_terms" />
              </div>
            </div>
          </DealerRoute>

          <Route>
            <Page404 />
          </Route>
        </Switch>

        {dealerConfig.cookieBanner && (
          <CookieBanner visible={!cookiesAccepted} />
        )}

        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default App;
