import React, { useRef, useState } from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

const LandingFaq = () => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const answerRef = useRef(null);
  const { t } = useTranslation();

  const faqData = [
    {
      key: "faq-1",
      question: t("Is MyWay Buy free?"),
      answer: () => {
        return (
          <p>
            {t("Yes, MyWay BUY is completely free")}.
            {t(
              "We make a guaranteed offer free of charge based on the details of your car"
            )}
            .
            {/* {t(
              "You then have the free choice whether or not to accept this offer"
            )}
            . */}
          </p>
        );
      },
    },
    {
      key: "faq-2",
      question: t("Why should I sell my car via MyWay BUY?"),
      answer: () => {
        return (
          <React.Fragment>
            <p>
              {t(
                "MyWay BUY takes all the stress out of selling your car and saves you considerable time and effort"
              )}
              .
              {t(
                "When you sell your car privately, you will soon encounter many obstacles"
              )}
              :
            </p>
            <ul
              style={{
                listStyleType: "disc",
                marginLeft: 16,
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <li>
                <p>
                  {t(
                    "You must advertise the car on various platforms and have detailed and clear photos taken for this purpose"
                  )}
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "You must have any repairs carried out and a costly pre-sale inspection, which is also only valid for two months"
                  )}
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "You are contacted by all kinds of buyers and must keep an overview of appointments"
                  )}
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "You must test drive potential buyers, which always entails a risk for your car and for which you must prepare documents and consult your insurance"
                  )}
                </p>
              </li>
              <li>
                <p>{t("You must negotiate the price with potential buyers")}</p>
              </li>
              <li>
                <p>
                  {t(
                    "You need to prepare a sales agreement and arrange the payment"
                  )}
                </p>
              </li>
            </ul>
            <p>
              {t("With MyWay BUY you don't have to worry about all this")}.
              {t(
                "There are only four simple steps inspect your car, receive an offer, meet at an inspection point to confirm the take-over and receive your payment within 24 hours"
              )}
              .
              {/* {t(
                "The offer you receive is immediately guaranteed, so no negotiations are involved"
              )}
              .
              {t(
                "In addition, the sale of your car can be fully arranged very quickly in this way"
              )}
              . */}
            </p>
          </React.Fragment>
        );
      },
    },
    {
      key: "faq-3",
      question: t("Which cars does MyWay BUY purchase?"),
      answer: () => {
        return (
          <p>
            {t(
              "MyWay BUY is interested in all car brands up to 150.000 km and 8 years old"
            )}
            .
            {t(
              "All necessary documents must be present and the vehicle must be roadworthy"
            )}
            .
            {t(
              "In order to confirm the sale, you must be the official owner of the car, or have a power of attorney for the sale"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-4",
      question: t("Which documents do I need to sell my car to MyWay BUY?"),
      answer: () => {
        return (
          <React.Fragment>
            {/* <p>
              {t(
                "The following documents are required for the sale of your car"
              )}
              :
            </p> */}
            <ul
              style={{
                listStyleType: "disc",
                marginLeft: 16,
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <li>
                <p>
                  {t(
                    "The double registration certificate for your car, officially known as the 'two-part registration certificate' (CIM)"
                  )}
                  .
                  {t("This consists of the 'vehicle' part and the 'home' part")}
                  .
                </p>
              </li>
              <li>
                <p>{t("The certificate of conformity (COC)")}</p>
              </li>
              <li>
                <p>
                  {t(
                    "If your car is older than four years, your most recent inspection certificate"
                  )}
                </p>
              </li>
              <li>
                <p>
                  {t(
                    "If the car is not registered in your name, a power of attorney to sell it from the official owner"
                  )}
                </p>
              </li>
            </ul>
            <p>
              {t(
                "In addition, you submit all additional documents that belong to the car at the time of sale. The maintenance booklet, technical manual, etc."
              )}
            </p>
          </React.Fragment>
        );
      },
    },
    {
      key: "faq-5",
      question: t("What does MyWay BUY base my bid on??"),
      answer: () => {
        return (
          <React.Fragment>
            <p>
              {t(
                "Our offer is based on the actual condition of your car based on the data and photos you provide"
              )}
              .
              {t(
                "These are studied by an expert and compared with European market data, allowing us to arrive at the best offer"
              )}
              .
            </p>
            <p>
              {t(
                "Because our offer is prepared by experienced experts (and not just by a program that does not take into account the actual condition of your car), we can make a guaranteed offer within 24 hours. This way you will not be faced with unpleasant surprises after the check"
              )}
              .
              {/* {t(
                "This way you will not be faced with unpleasant surprises after the check"
              )}
              . */}
            </p>
            <p>
              {t(
                "This check is carried out by My Way Buy or our independent partner that is active in more than 60 countries as a vehicle expert for inspections and damage determinations"
              )}
              .
              {t(
                " We check whether the actual condition of your car corresponds to the data and photos you sent in, after which we confirm your offer"
              )}
              .
            </p>
          </React.Fragment>
        );
      },
    },
    {
      key: "faq-6",
      question: t("Is the offer I get guaranteed?"),
      answer: () => {
        return (
          <p>
            {t(
              "Our offer is based on the data and photos you provide of your car and is 100% guaranteed if this data is accurate"
            )}
            .
            {t(
              " Our partner Dekra carries out an objective check of your car and on that basis we confirm your offer"
            )}
            .{t("The offer is valid for 7 days from inspection")}.
          </p>
        );
      },
    },
    {
      key: "faq-7",
      question: t(
        "Why don't I get a price right away when I submit my details?"
      ),
      answer: () => {
        return (
          <p>
            {t(
              "Unlike other sales websites, MyWay BUY's bid is prepared by experts, not just automatically calculated by an algorithm"
            )}
            .
            {t(
              ". Our offer is based on the actual condition of your car combined with live European market data, so that we can immediately guarantee the right price and you will not be faced with surprises afterwards"
            )}
            .
            {t(
              "Therefore, it takes a maximum of 24 hours to receive your guaranteed offer"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-8",
      question: t("How is the car inspected?"),
      answer: () => {
        return (
          <p>
            {t(
              "Unlike other car buyers, we ask you for many details and current photos of your car from the start"
            )}
            .
            {t(
              "In this way we are able to immediately prepare a guaranteed and correct offer"
            )}
            .
            {t(
              "You then take the car to an inspection point of Dekra, our independent partner that is active in more than 60 countries as a car expert for inspections and damage determinations"
            )}
            .
            {t(
              "Dekra carries out an objective inspection of your vehicle and we confirm your offer based on this information"
            )}
            .
            {t(
              "This is an aesthetic and technical inspection that takes about 30 minutes"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-9",
      question: t("What does Dekra's inspection report include?"),
      answer: () => {
        return (
          <p>
            {t(
              "Dekra checks the condition of the vehicle, both aesthetically and technically"
            )}
            .
            {t(
              " The quality of your vehicle is confirmed in an objective manner"
            )}
            .
            {/* {t(
              "Dekra is the market leader in vehicle inspections, and Dekra's expertise report is a great added value when you want to sell your vehicle"
            )}
            . */}
          </p>
        );
      },
    },
    {
      key: "faq-10",
      question: t("Where are the inspection and return points?"),
      answer: () => {
        return (
          <p>
            {t(
              "Here you will find an overview of the various Dekra locations where you can have your car inspected and returned"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-11",
      question: t(
        "Does my car have to be clean during the inspection and return?"
      ),
      answer: () => {
        return (
          <p>
            {t(
              "Yes, it is important that your car is clean both inside and out"
            )}
            .
            {t(
              "If this is not the case, our partner will not be able to make an accurate assessment of the condition of your car"
            )}
            .
            {t(
              " It must also be clean when the car is returned, otherwise the final check cannot take place"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-12",
      question: t("How does the sale take place?"),
      answer: () => {
        return (
          <p>
            {t(
              "Once your car has been inspected and the offer has been confirmed, take your car to a Dekra collection point"
            )}
            .
            {/* {t(
              "A final conformity check is carried out here, after which all documents for the sale are signed"
            )}
            . */}
            {t(
              "You leave your keys and the vehicle documents and receive the payment on your bank account, at the latest 48 hours after signing the contract"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-13",
      question: t("How do I receive my payment?"),
      answer: () => {
        return (
          <p>
            {t(
              "Once you have signed the acquisition contract, you will receive your payment via bank transfer"
            )}
            .
            {t(
              "Payment is made immediately, but can take a while due to the processing time of the bank"
            )}
            .
            {t(
              "The money is in your account at the latest 48 hours after signing the contract"
            )}
            .
          </p>
        );
      },
    },
    {
      key: "faq-14",
      question: t("Who is behind MyWay BUY?"),
      answer: () => {
        return (
          <p>
            {t(
              "MyWay BUY is a subsidiary of D'Ieteren Auto (est. 1805), the Belgian importer and retailer of Volkswagen, Audi, Seat, Cupra, Skoda, Bentley, Bugatti, Lamborghini & Porsche"
            )}
            .
            {t(
              "D'Ieteren Auto is the Belgian market leader in new vehicles on the one hand, and the second-hand car specialist with the brands MyWay, MyWayPRO and MyWayBUY on the other"
            )}
            .
            {/* {t(
              "With these foundations combined with live European market data, we are able to offer you the best price for your car"
            )}
            . */}
          </p>
        );
      },
    },
  ];

  const scrollToAnswers = () => {
    if (!answerRef?.current) return;
    if (window?.innerWidth >= 768) return;
    answerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const renderQuestion = (question, isActive) => {
    return (
      <div
        className={`block question cursor-pointer py-3 px-6 my-3 font-bold rounded-lg text-base leading-normal ${
          isActive
            ? "text-white bg-color_mywaybuy_one"
            : "text-primary bg-gray-100 hover:bg-gray-200"
        } qa-q-0`}
      >
        {question}
      </div>
    );
  };

  return (
    <div className="py-10 md:py-14 my-16 mb-0 md:mb-16 px-4 mx-auto max-w-screen-xl relative">
      <h3 className="text-left uppercase text-color_mywaybuy_success font-medium tracking-wider">
        {t("FAQ")}
      </h3>
      <h2 className="text-left text-4xl md:text-7xl max-w-2xl py-4 font-black leading-tight">
        {t("Frequently asked questions")}
      </h2>
      <div className="h-auto md:h-140 flex flex-nowrap flex-col md:flex-row md:px-10 py-12 my-4 rounded-3xl md:shadow-primary-far">
        <div className="w-full md:w-1/3 overflow-y-auto pr-6">
          <ul>
            {map(faqData, (faq, index) => {
              return (
                <li
                  key={`question-${faq?.key}`}
                  onClick={() => {
                    setActiveQuestion(index);
                    scrollToAnswers();
                  }}
                >
                  {renderQuestion(faq.question, activeQuestion === index)}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex-none px-10 hidden md:inline-block">
          <img
            src={require("../../assets/landing-page/dotted-line-vertical.svg")}
            alt="dashed vertical line"
            className="h-full"
          />
        </div>
        <div className="md:hidden">
          <img
            src={require("../../assets/landing-page/dotted-line-horizontal.svg")}
            alt="dashed horizontal line"
            className="w-full mt-6 mb-6 separator"
          />
        </div>
        <div
          ref={answerRef}
          className="w-full md:w-2/3 flex overflow-y-auto pr-6"
        >
          <ul className="flex-grow">
            {map(faqData, (faq, index) => {
              return (
                <li
                  key={`answer-${faq.key}`}
                  className={`${index !== activeQuestion ? "hidden" : ""}`}
                >
                  <div>
                    <h6 className="font-bold text-2xl md:text-4xl mb-6">
                      {faq.question}
                    </h6>
                    <div className="text-color_mywaybuy_four text-base leading-normal">
                      {faq.answer()}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="hidden sm:block bg-color_mywaybuy_warning w-9 h-9 rounded-lg absolute top-0 left-1/3 transform rotate-12 z-0"></div>
      <div className="hidden sm:block bg-color_mywaybuy_error w-6 h-6 rounded absolute bottom-0 right-5 transform rotate-6 z-0"></div>
    </div>
  );
};

export default LandingFaq;
