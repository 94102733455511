import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import LandingButton from "./LandingButton";
import LanguagePicker from "../LanguagePicker";
import { getDealerFromUrl } from "../../utils";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

const LandingHeader = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const scrollTo = (id) => {
    if (!document) return;
    return document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav className="nav fixed md:absolute top-0 left-0 w-full shadow-xl md:shadow-none z-50 font-mywaybuy text-color_mywaybuy_one">
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between py-4 px-4 relative bg-white md:bg-transparent">
        <div className="flex justify-between items-center h-8 md:h-auto">
          <div className="logo w-10 md:w-20 xl:w-28">
            <img
              src={require("../../assets/landing-page/mywaybuy-logo-vertical.svg")}
              alt="MyWay Buy logo"
              className="max-w-full"
            />
          </div>
          <div className="burger md:hidden">
            <div
              onClick={() => {
                setShowMobileMenu(!showMobileMenu);
              }}
              className="flex w-8 justify-center cursor-pointer"
            >
              {showMobileMenu ? (
                <img
                  src={require("../../assets/header/header-close.svg")}
                  alt="cross icon"
                  className="w-6"
                />
              ) : (
                <img
                  src={require("../../assets/header/header-menu.svg")}
                  alt="burger menu open icon"
                />
              )}
            </div>
          </div>
        </div>
        <ul
          className={`${
            showMobileMenu ? "" : "hidden"
          } mt-4 md:mt-0 navigation-list md:flex flex-col md:flex-row items-center text-base leading-normal`}
        >
          <li className="w-full md:w-auto">
            <a
              href="#who"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileMenu(false);
                scrollTo("who");
              }}
              className="cursor-pointer block md:inline-block mx-2 p-2 py-4 md:py-2 text-primary border-b-2 border-dashed border-color_mywaybuy_four md:border-b-0 text-center"
            >
              {t("What do we offer")}
            </a>
          </li>
          <li className="w-full md:w-auto">
            <a
              href="#how"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileMenu(false);
                scrollTo("how");
              }}
              className="cursor-pointer block md:inline-block mx-2 p-2 py-4 md:py-2 text-primary border-b-2 border-dashed border-color_mywaybuy_four md:border-b-0 text-center"
            >
              {t("How does it work")}
            </a>
          </li>
          <li className="w-full md:w-auto">
            <a
              href="#faq"
              onClick={(e) => {
                e.preventDefault();
                setShowMobileMenu(false);
                scrollTo("faq");
              }}
              className="cursor-pointer block md:inline-block mx-2 p-2 py-4 md:py-2 text-primary border-b-2 border-dashed border-color_mywaybuy_four md:border-b-0 text-center"
            >
              {t("Questions?")}
            </a>
          </li>
          <li className="w-full md:w-auto">
            <LandingButton
              text={t("Sell my car")}
              additionalClasses={"my-6 md:my-0 mx-0 md:mx-2"}
              onClick={() => {
                //Track buttonclick
                let aTrack = analyticstracker();
                let trackingInfo = TAGMANAGER_BUTTON_ARGS({
                  buttonName: "to-car-quotation",
                  buttonText: t("Sell my car"),
                  buttonType: "button-cta",
                  buttonCategory: "inbound-link",
                  buttonTarget: `/${getDealerFromUrl(
                    window.location.pathname
                  )}/inspection`,
                  buttonPosition: "header",
                });
                let event = {
                  event: "button-click",
                  info: JSON.parse(trackingInfo),
                };
                aTrack.trackEvent(event);

                history.push(
                  `/${getDealerFromUrl(window.location.pathname)}/inspection`
                );
              }}
            />
          </li>
          <li className="w-full md:w-auto">
            <a
              href={
                i18n?.language === "nl"
                  ? "https://myway.be/nl"
                  : "https://myway.be/fr"
              }
              className="cursor-pointer block md:inline-block mx-2 p-2 py-4 md:py-2 text-primary border-b-2 border-dashed border-color_mywaybuy_four md:border-b-0 text-center"
            >
              {t("Buy a car")}
            </a>
           
          </li>
          <li className="w-full md:w-auto flex justify-center items-center px-2 text-base">
            <LanguagePicker
              activeColor={"text-color_mywaybuy_one font-bold"}
              inactiveColor={"text-color_mywaybuy_one"}
              additionalClasses={"text-base"}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default LandingHeader;
