import React, { useContext, useEffect, useState } from "react";
import WizardButtons from "../WizardButtons";
import { useMutation, useQuery } from "graphql-hooks";
import DataContext from "../../../data/context";
import { filter, includes, keys, map, orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../ConfirmationModal";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const dataQuery = `query GeneralState {
 featureGroups {
    id  
    description
    priority
  }
  
   features {
    id    
    key
    description
    priority
    groupId
  }
  
}`;

export const featuresMutation = `mutation SetFeatures($key: String!, $data: [String]!) { 
        setFeatures(key: $key data: $data) {
            ok,           
        }
    }`;

const Features = () => {
  const { data, refetch: reFetchData } = useQuery(dataQuery);
  const [setFeatures] = useMutation(featuresMutation);
  const {
    inspection,
    setField,
    setValidationErrorsModalVisible,
    setNextWizardStep,
  } = useContext(DataContext);
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const [
    noFeaturesConfirmationBoxVisible,
    setNoFeaturesConfirmationBoxVisible,
  ] = useState(false);
  const [noFeaturesConfirmed, setNoFeaturesConfirmed] = useState(false);

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate(false);
    }
  }, [inspection]);

  useEffect(() => {
    reFetchData();
  }, [i18n.language]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "options",
    toolStepNumber: "4",
    toolType: "inspection-form",
    requestType: "inspection",
    //inspectionPartTwo: true
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });
  const validate = (showModal = true) => {
    let errors = {};

    if (inspection.features.length === 0 && noFeaturesConfirmed === false) {
      setNoFeaturesConfirmationBoxVisible(true);
      return false;
    }

    // if (inspection.features.length === 0) {
    //     errors['features'] = t("Please select an option");
    // }

    setErrors(errors);

    if (showModal && keys(errors).length > 0) {
      Object.values(errors).map((message) => {
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: "options",
          toolStepNumber: "4",
          errorType: "user-error",
          errorMessage: message,
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true
        });
        let event = {
          event: "tool-error",
          info: JSON.parse(errorTrackingInfo),
          commerce: JSON.parse(tagArgsCommerce),
        };
        aTrack.trackEvent(event);
      });
      setValidationErrorsModalVisible(errors);
    }
    return keys(errors).length <= 0;
  };

  const validator = () => {
    if (validate()) {
      return setFeatures({
        variables: {
          key: inspection.key,
          data: inspection.features,
        },
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.setFeatures &&
          result.data.setFeatures.ok
        ) {
          return true;
        }
        return false;
      });
    }
  };

  const handleChange = (option) => {
    if (!includes(inspection.features, option.key)) {
      setField("features", [...inspection.features, option.key]);
    } else {
      setField(
        "features",
        filter(inspection.features, (opt) => opt !== option.key)
      );
    }
  };

  const isFeatureSelected = (features, feature) => {
    return includes(features, feature.key);
  };

  const featureGroups =
    data && data.featureGroups
      ? orderBy(data.featureGroups, ["priority", "description"], ["asc"])
      : [];

  return (
    <div
      className="ml-8 mr-8 mt-0 lg:mt-10"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      {map(featureGroups, (group) => {
        const features = orderBy(
          filter(data.features, (f) => f.groupId === group.id),
          ["priority", "description"],
          ["asc", "desc"]
        );
        if (features.length) {
          return (
            <div key={`${group.id}`} className="mb-12">
              <div className="text-24 text-color_one font-bold mb-6">
                {" "}
                {group.description}
              </div>
              {map(features, (feature) => {
                const selected = isFeatureSelected(
                  inspection.features,
                  feature
                );

                return (
                  <div
                    key={`${group.key}_${feature.key}`}
                    className="flex bg-color_five border border-color_four rounded-lg mb-3 items-center p-4 cursor-pointer"
                    style={{ minHeight: 50 }}
                    onClick={() => handleChange(feature)}
                  >
                    <div
                      className="flex bg-white rounded items-center justify-center"
                      style={{ width: 20, height: 20 }}
                    >
                      {selected && (
                        <i className="fal fa-check text-14 font-bold text-color_three" />
                      )}
                    </div>
                    <div
                      className={`${
                        selected ? "text-color_one" : "text-color_three"
                      } pl-4 font-bold text-14 tracking-wide`}
                    >
                      {feature.description}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
      })}

      {noFeaturesConfirmationBoxVisible && (
        <ConfirmationModal
          title={t("Options")}
          text={t(
            "Are you sure that your vehicle is not equipped with one (or more) of these options?"
          )}
          acceptButtonText={t("yes")}
          declineButtonText={t("no")}
          onAccept={() => {
            setNextWizardStep();
            setNoFeaturesConfirmed(true);
          }}
          onDecline={() => {
            setNoFeaturesConfirmationBoxVisible(false);
          }}
          trackingEvent="tool-submit"
          trackingInfo={TAGMANAGER_TOOL_ARGS({
            toolStep: "options",
            toolStepNumber: "4",
            toolType: "inspection-form",
            requestType: "inspection",
            //inspectionPartTwo: true
          })}
          trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
            carConfigCode: inspection.key,
          })}
        />
      )}

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          toolStep: "options",
          toolStepNumber: "4",
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true
        })}
        trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
          carConfigCode: inspection.key,
        })}
      />
    </div>
  );
};

export default Features;
