import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { getDealerFromUrl } from "../utils";
import { StickyElements } from "../data/constants";
import DataContext from "../data/context";

import LanguagePicker from "./LanguagePicker";
import ConfirmationModal from "./ConfirmationModal";
import AuthButton from "./acount/AuthButton";
import HtmlBlock from "./HtmlBlock";
import LandingHeader from "./landing-page/LandingHeader";

const Header = ({ logoUrl }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [confirmationBoxVisible, setConfirmationBoxVisible] = useState(false);
  const {
    inspectionPending,
    dealer,
    dealerKey,
    dealerConfig,
    resetVehicleWizard,
  } = useContext(DataContext);
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();

  if (!dealer) {
    return null;
  }

  const getLogoUrl = () => {
    return dealerConfig.logo ? `/branding/${dealerConfig.logo}` : undefined;
  };

  const renderSmallNav = () => {
    return (
      <div className="absolute left-0 ml-10">
        <div
          id="nav-icon"
          className={navOpen ? "hidden" : ""}
          onClick={() => setNavOpen(true)}
        >
          <span className="bg-gray-800" />
          <span className="bg-gray-800" />
          <span className="bg-gray-800" />
        </div>
        <Fade direction="left" duration={150}>
          <div
            className={`flex flex-col fixed left-0 right-0 top-0 bottom-0 z-50 text-black ${
              !navOpen && "hidden"
            }`}
            style={{ background: "rgba(0, 0, 0, 0.4)" }}
          >
            <div className="flex flex-col w-3/4 bg-white text-gray-800 p-6 h-full justify-between">
              <div className="flex flex-col">
                <div className="flex justify-between items-center mb-10">
                  <LanguagePicker />
                  <i
                    className="fal fa-times text-gray-800 self-end text-24 cursor-pointer"
                    onClick={() => setNavOpen(false)}
                  />
                </div>

                {(dealer.email && dealer.email !== "") ||
                  (dealer.telephone && dealer.telephone !== "" && (
                    <div className="flex flex-col items-start">
                      {dealer.email && dealer.email !== "" && (
                        <a
                          className="mt-6 ml-5 flex items-center justify-center tracking-wide font-bold"
                          href={`mailto:${dealer.email}`}
                        >
                          <i className="far fa-envelope mr-2" />
                          {dealer.email}
                        </a>
                      )}

                      {dealer.telephone && dealer.telephone !== "" && (
                        <a
                          className="mt-6 ml-5 flex items-center justify-center tracking-wide font-bold"
                          href={`tel:${dealer.telephone}`}
                        >
                          <i className="far fa-phone-alt mr-2" />
                          {dealer.telephone}
                        </a>
                      )}
                    </div>
                  ))}
              </div>
              <div className="flex flex-col">
                <div className="text-20 font-bold mb-6 tracking-wide">
                  {dealer.name}
                </div>

                {dealer.address && dealer.address !== "" && (
                  <div className="tracking-wide text-color_three mb-1">
                    {dealer.address}
                  </div>
                )}

                {dealer.postal &&
                  dealer.postal !== "" &&
                  dealer.city &&
                  dealer.city !== "" && (
                    <div className="font-md tracking-wide text-color_three mb-6">
                      {dealer.postal} {dealer.city}
                    </div>
                  )}

                <div className="text-color_one text-24">
                  {dealer.facebook && (
                    <a
                      href={dealer.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-square mr-2" />
                    </a>
                  )}

                  {dealer.instagram && (
                    <a
                      href={dealer.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram-square mr-2" />
                    </a>
                  )}

                  {dealer.twitter && (
                    <a
                      href={dealer.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter-square mr-2" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  };

  const handleLogoClick = () => {
    if (inspectionPending && window.location.pathname.includes("/inspection")) {
      setConfirmationBoxVisible(true);
    } else {
      const rootUrl = `/${getDealerFromUrl(window.location.pathname)}`;
      if (dealer.website && window.location.pathname === rootUrl) {
        window.location.href = dealer.website;
      } else if (
        window.location.href !== "/inspection" ||
        window.location.href !==
          `/${getDealerFromUrl(window.location.pathname)}`
      ) {
        resetVehicleWizard();
        history.push(`/${getDealerFromUrl(window.location.pathname)}`);
      } else {
        window.location.reload();
      }
    }
  };

  if (location && location.pathname?.includes("/landing")) {
    return (
      <header className="mx-auto w-full px-4" style={{ maxWidth: 1280 }}>
        <LandingHeader />
      </header>
    );
  }

  return (
    <React.Fragment>
      <div
        className={`${StickyElements.header} header flex w-full justify-center items-center h-16 m:h-20 relative sticky top-0 bg-white z-30`}
      >
        <div
          className="flex flex-1 items-center justify-center md:justify-between md:w-3/5 px-8 lg:px-0 py-0 lg:py-12 w-full h-full"
          style={{ maxWidth: 1024 }}
        >
          <div className="flex md:hidden items-center">{renderSmallNav()}</div>

          <div className="logo-container flex items-center cursor-pointer">
            <img
              className="logo"
              src={getLogoUrl()}
              alt="logo"
              style={{ width: 150 }}
              onClick={handleLogoClick}
            />
          </div>
          <div className="hidden md:flex text-14 flex-1 justify-end">
            {/*<a className="mr-4 flex items-center justify-center tracking-wide font-bold" href={`mailto:${dealer.email}`}><i className="far fa-envelope mr-2"/>{dealer.email}</a>*/}
            {/*<a className="mr-4 flex items-center justify-center tracking-wide font-bold" href={`{{dealer.telephone}}`}><i className="far fa-phone-alt mr-2"/>{dealer.telephone}</a>*/}

            {dealerConfig &&
              dealerConfig.header &&
              dealerConfig.header.html &&
              dealerConfig.header.html !== "" && (
                <HtmlBlock
                  html={dealerConfig.header.html}
                  script={dealerConfig.header.script}
                />
              )}

            <div className="md:flex md:justify-center md:items-center md:ml-4">
              <LanguagePicker />
            </div>

            {dealerConfig.account && (
              <div className="ml-4">
                <AuthButton />
              </div>
            )}
          </div>
        </div>
      </div>

      {confirmationBoxVisible && (
        <ConfirmationModal
          title={t("We don't like to see you go...")}
          text={t("Are you sure you want to leave this takeover request?")}
          acceptButtonText={t("Finish takeover")}
          declineButtonText={t("yes, leave")}
          onAccept={() => {
            setConfirmationBoxVisible(false);
          }}
          onDecline={() => {
            history.push(`/${dealerKey}`);
            window.location.reload();
          }}
        />
      )}
    </React.Fragment>
  );
};
export default Header;
