import React from "react";
import { useTranslation } from "react-i18next";
import {isMobile} from 'react-device-detect';
import QRCode from "qrcode.react";

const ValuationToInspection = ({ goToInspection, qrCodeUrl }) => {
  const { t } = useTranslation();

  return (
    <div
      className="bg-color_mywaybuy_three p-8 flex flex-col-reverse sm:flex-row"
      style={{ borderRadius: 8 }}
    >
      <div className="w-full sm:w-3/5">
        <h3
          className="font-bold text-20 md:text-24"
          dangerouslySetInnerHTML={{
            __html: t(
              "Want a more {{ styling }} accurate price indication {{ stylingEnd }} ?",
              {
                styling: '<span class="text-color_mywaybuy_two">',
                stylingEnd: "</span>",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></h3>
        <p
          className="leading-6 mb-10 text-color_mywaybuy_four"
          dangerouslySetInnerHTML={{
            __html: t(
              "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 6 steps! {{stylingEnd}} It’ll only take up 15 minutes of your time and we will guide you through the whole process.",
              {
                styling: '<span class="font-bold">',
                stylingEnd: "</span />",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></p>
        {!isMobile && (
          <>
            <h4 className="font-bold text-16 md:text-20 mb-5">
              {t("No smartphone?")}
            </h4>

            <p className="leading-6 mb-10 text-color_mywaybuy_four">
              {t(
                "No problem! Do the inspection on your desktop. Just make sure you have a camera on hand to upload pictures of your car."
              )}
            </p>
          </>
        )}
        <div
          className="inline-block py-3 px-5 text-white text-16 font-bold cursor-pointer"
          style={{
            minHeight: 40,
            borderRadius: 8,
            background: "rgb(111,187,56)",
          }}
          onClick={goToInspection}
        >
          {t("Start inspection")}
        </div>
      </div>
      {!isMobile && (
        <div className="w-full sm:w-2/5 flex flex-col items-center justify-center pb-8 sm:pb-0 pl-0 sm:pl-8">
          <QRCode
            size={240}
            value={qrCodeUrl}
            includeMargin
            level="H"
            imageSettings={{
              height: 80,
              width: 80,
              src: require("../../assets/mywaybuy-logo-vertical.svg"),
              excavate: true,
            }}
          />
          <p
            className="leading-6 mt-5 text-center text-color_mywaybuy_four"
            dangerouslySetInnerHTML={{
              __html: t(
                "Grab your smartphone and {{ styling }} scan the QR-code {{ stylingEnd }} to start the detailed inspection. ",
                {
                  styling: '<span class="font-bold">',
                  stylingEnd: "</span>",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
          ></p>
        </div>
      )}
    </div>
  );
};

export default ValuationToInspection;
