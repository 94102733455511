import React, { useContext, useEffect, useRef, useState } from "react";
import WizardButtons from "../WizardButtons";
import { useMutation, useQuery } from "graphql-hooks";
import DataContext from "../../../data/context";
import { filter, find, keys, map, orderBy } from "lodash";
import YesNo from "../../input/YesNo";
import { base64ToFile, convertYesNoToBoolean } from "../../../utils";
import { v4 } from "uuid";
import LocationOverview from "../damages/LocationOverview";
import LocationDetail from "../damages/LocationDetail";
import CameraModal from "../../camera/CameraModal";
import DamagesList from "../damages/DamagesList";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ConfirmationModal from "../../ConfirmationModal";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const dataQuery = `query DamagesData {
  damageLocations {
    id
    key   
    description
  }
}`;

export const FileUploadMutation = `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok                
            url
        }
    }`;

const damageViews = {
  hasDamages: "hasDamages",
  overview: "overview",
  detail: "detail",
  list: "list",
};

export const damagesMutation = `mutation SetDamages($key: String!, $data: DamageDataInputType!) { 
        setDamages(key: $key data: $data) {
            ok           
        }
    }`;

const Damages = ({ onChange }) => {
  const {
    inspection,
    setField,
    addDamage,
    addDamagePictures,
    removeDamagePicture,
    setValidationErrorsModalVisible,
    setNextWizardStep,
  } = useContext(DataContext);
  const { data, refetch: reFetchData } = useQuery(dataQuery);
  const [setDamagesMutation] = useMutation(damagesMutation);
  const [fileUploadMutation] = useMutation(FileUploadMutation);
  const [cameraVisible, setCameraVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(undefined);
  const [noDamagesConfirmationBoxVisible, setNoDamagesConfirmationBoxVisible] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [view, setView] = useState(
    inspection.noDamages !== undefined && inspection.noDamages === false
      ? damageViews.overview
      : damageViews.hasDamages
  );
  const { t, i18n } = useTranslation();
  const inputFile = useRef(null);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "damage",
    toolStepNumber: "5",
    toolType: "inspection-form",
    requestType: "inspection",
    //inspectionPartTwo: true,
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });

  useEffect(() => {
    if (inspection.noDamages !== undefined && !inspection.noDamages) {
      // let top = view === damageViews.overview ? 500 : 250;
      //
      // setTimeout(function () {
      //     window.scrollTo({
      //         top: top,
      //         left: 0,
      //         behavior: 'smooth'
      //     });
      // }, 500);
      //
    }
  }, [inspection.noDamages, view]);

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      Object.values(errors).map((message) => {
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: "damage",
          toolStepNumber: "5",
          errorType: "user-error",
          errorMessage: message,
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true,
        });
        let event = {
          event: "tool-error",
          info: JSON.parse(errorTrackingInfo),
        };
        aTrack.trackEvent(event);
      });
      validate(false);
    }
  }, [inspection]);

  useEffect(() => {
    reFetchData();
  }, [i18n.language]);

  const validate = (showModal = true) => {
    let errors = {};

    if (
      !view === damageViews.hasDamages &&
      inspection.damages.length === 0 &&
      !inspection.noDamages
    ) {
      errors["damages"] = t("Please define the damages.");

      setErrors(errors);

      if (showModal && keys(errors).length > 0) {
        setValidationErrorsModalVisible(errors);
      }
      return false;
    } else {
      if (inspection.damages.length === 0) {
        setNoDamagesConfirmationBoxVisible(true);
        return false;
      }
    }

    return true;
  };

  const setDamages = (noDamages = false) => {
    return setDamagesMutation({
      variables: {
        key: inspection.key,
        data: {
          noDamages: noDamages,
          damages: inspection.noDamages
            ? []
            : map(inspection.damages, (d) => {
                return {
                  id: d.id,
                  location: d.location,
                  pictures: d.pictures,
                };
              }),
        },
      },
    }).then((result) => {
      if (
        result &&
        result.data &&
        result.data.setDamages &&
        result.data.setDamages.ok
      ) {
        return true;
      }
      return false;
    });
  };

  const validator = () => {
    if (validate()) {
      return setDamages();
    }
  };

  const handleLocationClick = (location) => {
    const damages = filter(inspection.damages, (d) => d.location === location);

    setCurrentLocation(location);

    if (damages.length === 0) {
      if (isMobile) {
        setView(damageViews.detail);
        setCameraVisible(true);
      } else {
        inputFile.current.click();
      }
    } else {
      setView(damageViews.detail);
    }
  };

  const handleDamageCreate = (file, dataUri, location) => {
    let tmpFile = file;
    if (dataUri) {
      tmpFile = base64ToFile(dataUri, `${v4()}.png`);
    }

    const clientDamageId = v4();
    addDamage(clientDamageId, location);

    if (isMobile) {
      // setCameraVisible(false);
    } else {
      setView(damageViews.detail);
    }

    fileUploadMutation({ variables: { file: tmpFile } })
      .then((res) => {
        const { ok, url } = res.data.fileUpload;
        if (ok) {
          addDamagePictures(clientDamageId, [url]);

          if (inputFile) {
            inputFile.current.value = "";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDamagePictureDelete = (damage_id, picture_url) => {
    removeDamagePicture(damage_id, picture_url);
  };

  const backToOverview = () => {
    setCurrentLocation(undefined);
    setView(damageViews.overview);
  };

  const damageLocations =
    data && data.damageLocations
      ? orderBy(data.damageLocations, ["description"], ["asc"])
      : [];

  const renderHasDamages = () => {
    return (
      <React.Fragment>
        <div className="mb-6 mt-10">
          <span className="text-color_one text-24 font-bold mb-4">
            {t("Is your car damaged in any way?")}
          </span>
        </div>

        <p className="text-color_three text-14 mb-8 leading-5">
          {t(
            "Every used car has some kind of damage, big or small. Go on a quest to find each and every damage. A spot on the front seat or a small scratch on a rim? This way we are able to calculate a correct price at first glance. All cars will be checked upon handover; uncovered damages will lower your cars worth."
          )}
        </p>

        <div className="w-12 mb-10">
          <YesNo
            value={
              inspection.noDamages !== undefined
                ? inspection.noDamages
                  ? "no"
                  : "yes"
                : undefined
            }
            onChange={(value) => {
              setField("noDamages", !convertYesNoToBoolean(value));

              if (convertYesNoToBoolean(value)) {
                setView(damageViews.overview);
              } else {
                setNoDamagesConfirmationBoxVisible(true);
              }
            }}
            error={errors["noDamages"] ? true : false}
          />
        </div>

        <WizardButtons
          validatorFunction={validator}
          trackingEvent="tool-submit"
          trackingInfo={TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "damage",
            toolStepNumber: "5",
            toolType: "inspection-form",
            requestType: "inspection",
            //inspectionPartTwo: true,
          })}
          trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
            carConfigCode: inspection.key,
          })}
        />
      </React.Fragment>
    );
  };

  const renderOverView = () => {
    return (
      <React.Fragment>
        {/*<div className="mb-6 mt-10">*/}
        {/*    <span className="text-color_one text-24 font-bold mb-4">{t('Damages')}</span>*/}
        {/*</div>*/}
        <LocationOverview
          damageLocations={damageLocations}
          onLocationClick={handleLocationClick}
          selectedLocation={currentLocation}
        />

        <div
          className="flex flex-1 cursor-pointer text-14 text-color_one uppercase justify-center items-center mt-8 mb-8 tracking-widest font-bold border rounded-full border-color_one"
          style={{ minHeight: 45 }}
          onClick={() => setView(damageViews.list)}
        >
          {`${t("View all damages")} (${inspection.damages.length})`}
          <i className="fal fa-chevron-right ml-4" />
        </div>

        <WizardButtons
          validatorFunction={validator}
          trackingEvent="tool-submit"
          trackingInfo={TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "damage",
            toolStepNumber: "5",
            toolType: "inspection-form",
            requestType: "inspection",
            //inspectionPartTwo: true,
          })}
          trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
            carConfigCode: inspection.key,
          })}
        />
      </React.Fragment>
    );
  };

  const renderDetail = () => {
    return (
      <React.Fragment>
        <div
          className="text-12 text-color_three tracking-wider font-bold cursor-pointer"
          onClick={backToOverview}
        >
          <i className="fal fa-chevron-left mr-4" />
          <span className="uppercase">{t("location overview")}</span>
        </div>

        <LocationDetail
          location={find(damageLocations, (loc) => loc.key === currentLocation)}
          onAddPictureClicked={() => {
            if (isMobile) {
              setCameraVisible(true);
            } else {
              inputFile.current.click();
            }
          }}
          onDeletePicture={(damage_id, picture_url) =>
            handleDamagePictureDelete(damage_id, picture_url)
          }
          backToOverview={() => backToOverview()}
        />

        <div
          className="flex flex-1 cursor-pointer text-14 text-color_one uppercase justify-center items-center mt-8 mb-8 tracking-widest font-bold border rounded-full border-color_one text-center"
          style={{ minHeight: 45 }}
          onClick={backToOverview}
        >
          {t("Back to overview")}
          <i className="fal fa-chevron-right ml-4 mr-4" />
        </div>
      </React.Fragment>
    );
  };

  const renderList = () => {
    return (
      <React.Fragment>
        <div
          className="text-12 text-color_three tracking-wider font-bold"
          onClick={backToOverview}
        >
          <i className="fal fa-chevron-left mr-4" />
          <span className="uppercase">{t("location overview")}</span>
        </div>

        <DamagesList
          damageLocations={damageLocations}
          onDeletePicture={(damage_id, picture_url) =>
            handleDamagePictureDelete(damage_id, picture_url)
          }
        />

        <div
          className="flex flex-1 cursor-pointer text-14 text-color_one uppercase justify-center items-center mt-8 mb-8 tracking-widest font-bold border rounded-full border-color_one text-center"
          style={{ minHeight: 45 }}
          onClick={backToOverview}
        >
          {t("Back to overview")}
          <i className="fal fa-chevron-right ml-4 mr-4" />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className="ml-8 mr-8 mt-0 lg:mt-10"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      {view === damageViews.hasDamages && renderHasDamages()}
      {view === damageViews.overview && renderOverView()}
      {view === damageViews.detail && renderDetail()}
      {view === damageViews.list && renderList()}

      {inspection.noDamages !== undefined && !inspection.noDamages && (
        <React.Fragment>
          <CameraModal
            isOpen={cameraVisible}
            overlay={null}
            onClose={() => setCameraVisible(false)}
            onChooseFile={(file) => {
              handleDamageCreate(file, null, currentLocation);
            }}
            onPictureTaken={(data) => {
              handleDamageCreate(null, data, currentLocation);
            }}
          />

          <input
            type="file"
            accept="image/*"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) => {
              handleDamageCreate(e.target.files[0], null, currentLocation);
            }}
          />
        </React.Fragment>
      )}

      {/*{errors && keys(errors).length > 0 &&*/}
      {/*<div className="flex items-center justify-center mb-2">*/}
      {/*    <div className="text-color_error text-12"> {errors["damages"]}</div>*/}
      {/*</div>}*/}

      {noDamagesConfirmationBoxVisible && (
        <ConfirmationModal
          title={t("No damages?")}
          text={t(
            "We have learned from our experiences in the past that many used cars have minor damages. Are you sure you want to continue without adding damage pictures?"
          )}
          acceptButtonText={t("yes")}
          declineButtonText={t("no")}
          onAccept={() => {
            setNoDamagesConfirmationBoxVisible(false);
            setField("noDamages", true);
            if (setDamages(true)) {
              setNextWizardStep();
            }
          }}
          onDecline={() => {
            setNoDamagesConfirmationBoxVisible(false);
            setField("noDamages", false);
            setView(damageViews.overview);
          }}
          trackingEvent="tool-submit"
          trackingInfo={TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "damage",
            toolStepNumber: "5",
            toolType: "inspection-form",
            requestType: "inspection",
            //inspectionPartTwo: true,
          })}
          trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
            carConfigCode: inspection.key,
          })}
        />
      )}
    </div>
  );
};

export default Damages;
