import i18n from "../../i18n";
import React from "react";
import Make from "./wizardsteps/Make";
import FirstRegistrationYear from "./wizardsteps/FirstRegistrationYear";
import Model from "./wizardsteps/Model";
import BodyType from "./wizardsteps/BodyType";
import Fuel from "./wizardsteps/Fuel";
import Transmission from "./wizardsteps/Transmission";
import Mileage from "./wizardsteps/Mileage";
import Identification from "./wizardsteps/Identification";
import FirstRegistrationMonth from "./wizardsteps/FirstRegistrationMonth";
import Engine from "./wizardsteps/Engine";
import Purchase from "./wizardsteps/Purchase";
import {filter} from "lodash";

const wizardSteps = [
    {
        title: i18n.t("Make"),
        fieldNames: ['make'],
        component: <Make/>,
        required: () => true
    },
    {
        title: i18n.t("1st registration year"),
        fieldNames: ['firstRegistrationYear'],
        component: <FirstRegistrationYear/>,
        required: () => true
    },
    {
        title: i18n.t("1st registration month"),
        fieldNames: ['firstRegistrationMonth'],
        component: <FirstRegistrationMonth/>,
        required: () => true
    },
    {
        title: i18n.t("Model"),
        fieldNames: ['model'],
        component: <Model/>,
        required: () => true
    },
    {
        title: i18n.t("Body type"),
        fieldNames: ['body'],
        component: <BodyType/>,
        required: () => true
    },
    {
        title: i18n.t("Fuel"),
        fieldNames: ['fuel'],
        component: <Fuel/>,
        required: () => true
    },
    {
        title: i18n.t("Engine"),
        fieldNames: ['engineRange'],
        component: <Engine/>,
        required: () => true
    },
    {
        title: i18n.t("Transmission"),
        fieldNames: ['transmission'],
        component: <Transmission/>,
        required: () => true
    },
    {
        title: i18n.t("Mileage"),
        fieldNames: ['mileage'],
        component: <Mileage/>,
        required: () => true
    },
    {
        title: i18n.t("Purchase"),
        fieldNames: ['purchase'],
        component: <Purchase/>,
        required: (config) => config.purchaseProject
    },
    {
        title: i18n.t("Identification"),
        fieldNames: ['socialVerified'],
        component: <Identification/>,
        required: () => true
    },
];

export const getWizardStepsByDealerConfig = (config) => {
    return filter(wizardSteps, (s) => s.required(config))
}
