import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getDealerFromUrl } from "../../utils";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

import LandingButton from "./LandingButton";

const LandingHero = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="pt-28 md:pt-56 px-4 bg-gradient-to-b from-color_mywaybuy_background">
      <div className="mx-auto flex flex-col md:flex-row max-w-screen-xl">
        <div className="md:flex-1">
          <div className="text-center md:text-left pb-5">
            <p className="inline-block px-16 md:px-6 py-3 bg-color_mywaybuy_two bg-opacity-25 text-color_mywaybuy_two text-lg md:text-sm rounded-lg">
              My Way Buy
            </p>
          </div>
          <div className="pb-16 md:pb-0">
            <h1
              dangerouslySetInnerHTML={{
                __html: t(
                  "The sale of your car is {{ styling }}guaranteed{{ stylingEnd }} with My Way Buy.",
                  {
                    styling: '<span class="text-color_mywaybuy_two">',
                    stylingEnd: "</span>",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
              className="text-4xl md:text-6xl font-bold leading-tight md:leading-none text-center md:text-left"
            ></h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "With My Way Buy you sell your car {{ styling }}easily and safely{{ stylingEnd }}. You receive an advantageous quote, after that visit one of our inspection points for a take-over. Safe and fast payment within 24 hours.",
                  {
                    styling: "<strong>",
                    stylingEnd: "</strong>",
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
              className="text-base leading-normal text-color_mywaybuy_four max-w-md mx-auto md:mx-0 py-6 text-center md:text-left"
            ></p>
            <LandingButton
              text={t("Receive your free offer")}
              onClick={() => {
                //Track buttonclick
                let aTrack = analyticstracker();
                let trackingInfo = TAGMANAGER_BUTTON_ARGS({
                  buttonName: "to-car-quotation",
                  buttonText: t("Receive your free offer"),
                  buttonType: "button-cta",
                  buttonCategory: "inbound-link",
                  buttonTarget: `/${getDealerFromUrl(
                    window.location.pathname
                  )}/inspection`,
                  buttonPosition: "home-hero",
                });
                let event = {
                  event: "button-click",
                  info: JSON.parse(trackingInfo),
                };
                aTrack.trackEvent(event);

                history.push(
                  `/${getDealerFromUrl(window.location.pathname)}/inspection`
                );
              }}
              additionalClasses={"my-6 md:my-0"}
            />
          </div>
        </div>
        <div className="md:flex-1 relative">
          <img
            src={require("../../assets/landing-page/seller-and-buyer.svg")}
            alt={"My Way Buy seller and buyer shaking hands"}
            className="w-full"
          />
          <a
            href="mailto:info@mywaybuy.be"
            className="flex cursor-pointer items-center transition-all duration-100 transform hover:shadow-xl hover:scale-95 shadow-2xl rounded-full relative md:absolute -top-14 md:top-auto md:bottom-1/4 md:left-0 lg:-left-10 bg-white"
            style={{ maxWidth: "24rem" }}
            onClick={() => {
              //Track buttonclick
              let aTrack = analyticstracker();
              let trackingInfo = TAGMANAGER_BUTTON_ARGS({
                buttonName: "to-mail",
                buttonText: t("Send an e-mail"),
                buttonType: "button",
                buttonCategory: "outbound-link",
                buttonTarget: "mailto:info@mywaybuy.be",
                buttonPosition: "home-hero",
              });
              let event = {
                event: "button-click",
                info: JSON.parse(trackingInfo),
              };
              aTrack.trackEvent(event);
            }}
          >
            <div className="w-10 flex-none m-1">
              <img
                src={require("../../assets/landing-page/seller-face.svg")}
                alt={"My Way Buy seller face"}
                className="w-full"
              />
            </div>
            <div className="flex-grow ml-2 mr-4">
              <p className="font-bold text-sm leading-none">
                {t("Questions?")}
              </p>
              <p className="text-color_mywaybuy_four text-sm leading-relaxed">
                {t("Send an e-mail")}
              </p>
            </div>
            <div
              className="w-10 h-10 flex-none rounded-full flex justify-center items-center m-1"
              style={{ backgroundColor: "rgb(255, 77, 86)" }}
            >
              <img
                src={require("../../assets/landing-page/enveloppe.svg")}
                alt={"Enveloppe icon"}
              />
            </div>
          </a>
          <div className="hidden sm:block bg-color_mywaybuy_error w-3 h-3 rounded absolute bottom-14 left-0 z-0"></div>
          <div className="hidden sm:block bg-color_mywaybuy_error w-5 h-5 rounded absolute top-1/2 -right-10 transform rotate-45 z-0"></div>
          <div className="hidden sm:block bg-color_mywaybuy_warning w-4 h-4 rounded absolute top-1/3 -left-14 transform rotate-45 z-0"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
