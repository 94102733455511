import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { keys, map, range } from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const FirstRegistrationYear = () => {
  const { inspection, setField, setNextVehicleDataStep } =
    useContext(DataContext);
  const [showHelp, setShowHelp] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "first-registration",
    toolStepNumber: "2",
    requestType: "car-quotation",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};

    if (!inspection.firstRegistrationYear) {
      errors["firstRegistrationYear"] = t(
        "Please select the first registration year"
      );
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "first-registration",
        toolStepNumber: "2",
        requestType: "car-quotation",
        errorType: "user-error",
        errorMessage: errors.firstRegistrationYear,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const handleYearClick = (e, year) => {
    setField("firstRegistrationYear", year);
    // let aTrack = analyticstracker(); // Tracking of first registration gets submit at month-step
    // aTrack.trackInteraction(e);

    setNextVehicleDataStep();
  };

  const years = range(
    new Date().getFullYear() - 26,
    new Date().getFullYear() + 1
  ).reverse();

  const handleShowHelp = () => {
    if (!showHelp) {
      setShowHelp(true);
    }
  };

  const handleHideHelp = () => {
    setTimeout(() => {
      setShowHelp(false);
    }, 250);
  };

  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("1st registration")}
      </div>

      <div className="flex">
        <div
          className="text-16 leading-6 mb-4 mt-6"
          dangerouslySetInnerHTML={{
            __html: t(
              "Select the {{styling}} year {{stylingEnd}} of first registration",
              {
                styling: '<span class="font-bold">',
                stylingEnd: "</span />",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></div>
        <div className="flex items-center mb-4 mt-6 ml-1 relative">
          <div
            onMouseEnter={handleShowHelp}
            onMouseLeave={handleHideHelp}
            onClick={() => setShowHelp(!showHelp)}
          >
            <i className="fal fa-question-circle ml-1 text-20 text-gray-700 hover:text-gray-900 cursor-pointer " />

            {showHelp && (
              <div
                className="absolute right-0 top-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50"
                style={{ top: 25, width: 300 }}
              >
                <img
                  src={require("../../../assets/help-images/first-registration.jpg")}
                  alt=""
                  style={{ maxWidth: "100%" }}
                  className="rounded"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mb-10">
        {map(years, (year, i) => {
          const selected = year === inspection.firstRegistrationYear;
          const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "first-registration",
            toolStepNumber: "2",
            requestType: "car-quotation",
            toolStepOption: year,
          });
          return (
            <div
              key={`y_${year}`}
              className={`w-1/3 mb-2 ${i % 3 !== 2 ? "pr-2" : ""}`}
            >
              <div
                className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                  selected
                    ? "border-color_one text-color_one"
                    : "border-color_five text-color_three"
                } hover:border-color_one hover:text-color_one`}
                style={{ height: 70 }}
                onClick={(e) => handleYearClick(e, year)}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                data-tracking-commerce={tagArgsCommerce}
              >
                {year}
              </div>
            </div>
          );
        })}
      </div>

      <WizardError errors={errors} />

      <WizardButtons validatorFunction={validator} />
    </div>
  );
};

export default FirstRegistrationYear;
