import React, { useContext, useEffect, useState } from "react";
import { filter, find, forEach, includes, keys, map, orderBy } from "lodash";
import YesNo from "../../input/YesNo";
import { useMutation, useQuery } from "graphql-hooks";
import DataContext from "../../../data/context";
import WizardButtons from "../WizardButtons";
import { useTranslation } from "react-i18next";
import DatePicker from "../../input/DatePicker";
import TextAreaField from "../../input/TextAreaField";
import TagManager from "react-gtm-module";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const dataQuery = `query ChecksData {  
  checkGroups {
    id
    name
    priority
    description
  }
  
  checks {
    id
    key
    type
    description
    priority
    groupId
    mandatory
  }
}`;

export const checksMutation = `mutation SetChecks($key: String!, $data: [CheckInputType]!) { 
        setChecks(key: $key data: $data) {
            ok           
        }
    }`;

const Checks = () => {
  const { inspection, setCheckValue, setValidationErrorsModalVisible } =
    useContext(DataContext);
  const { data, refetch: reFetchData } = useQuery(dataQuery);
  const [setChecksMutation] = useMutation(checksMutation);
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();

  const isCheckSelected = (checks, check) => {
    return includes(checks, check.key);
  };

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate(false);
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "extra-info",
    toolStepNumber: "6",
    toolType: "inspection-form",
    requestType: "inspection",
    //inspectionPartTwo: true,
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });
  useEffect(() => {
    reFetchData();
  }, [i18n.language]);

  const validate = (showModal = true) => {
    let errors = {};

    forEach(data.checks, (check) => {
      if (check.mandatory) {
        const checkValue = find(inspection.checks, (c) => c.key === check.key);
        if (!checkValue) {
          errors[`check_${check.key}`] = `${check.description} ${t(
            "is required"
          )}`;
        }
      }
    });

    setErrors(errors);

    if (showModal && keys(errors).length > 0) {
      Object.values(errors).map((message) => {
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: "extra-info",
          toolStepNumber: "6",
          errorType: "user-error",
          errorMessage: message,
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true,
        });
        let event = {
          event: "tool-error",
          info: JSON.parse(errorTrackingInfo),
          commerce: JSON.parse(tagArgsCommerce),
        };
        aTrack.trackEvent(event);
      });
      setValidationErrorsModalVisible(errors);
    }

    return keys(errors).length <= 0;
  };

  const validator = () => {
    if (validate()) {
      return setChecksMutation({
        variables: {
          key: inspection.key,
          data: map(inspection.checks, (c) => {
            return {
              key: c.key,
              value: c.value,
            };
          }),
        },
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.setChecks &&
          result.data.setChecks.ok
        ) {
          return true;
        }
        return false;
      });
    }
  };

  const getComponentByType = (check, inspectionCheck) => {
    if (check.type === "BOOLEAN") {
      return (
        <YesNo
          value={inspectionCheck ? inspectionCheck.value : undefined}
          onChange={(value) => {
            setCheckValue(check.key, value);
          }}
          error={errors[`check_${check.key}`]}
        />
      );
    } else if (check.type === "DATE") {
      return (
        <div>
          <DatePicker
            value={
              inspectionCheck && inspectionCheck.value
                ? new Date(inspectionCheck.value)
                : undefined
            }
            minDate={new Date()}
            hasError={errors[`check_${check.key}`]}
            onChange={(value) => setCheckValue(check.key, value.fullString)}
          />
        </div>
      );
    } else if (check.type === "TEXT") {
      return (
        <div>
          <TextAreaField
            value={
              inspectionCheck && inspectionCheck.value
                ? inspectionCheck.value
                : ""
            }
            onChange={(value) => setCheckValue(check.key, value)}
            errorText={errors[`check_${check.key}`]}
            heightClass="h-16"
          />
        </div>
      );
    } else {
      return <div>Not implemented</div>;
    }
  };

  const renderCheck = (check, i) => {
    const selected = isCheckSelected(inspection.checks, check);
    const checkValue = find(inspection.checks, (c) => c.key === check.key);

    return (
      <div
        key={`${check.key}${i}`}
        className={`flex ${
          check.type !== "TEXT" ? "items-center" : ""
        } justify-between mb-3`}
      >
        <div
          className={`${
            selected ? "text-color_one" : "text-color_three"
          } font-bold text-14 tracking-wide`}
        >
          {check.description}
        </div>

        {getComponentByType(check, checkValue)}
      </div>
    );
  };

  const checkGroups =
    data && data.checkGroups
      ? orderBy(data.checkGroups, ["priority", "description"], ["asc"])
      : [];
  const checksNotGrouped =
    data && data.checks
      ? orderBy(
          filter(data.checks, (f) => f.groupId === null),
          ["priority", "description"],
          ["desc", "asc"]
        )
      : [];

  return (
    <div
      className="ml-10 mr-10 mt-0 lg:mt-12"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      {/*<div className="mb-6 mt-10">*/}
      {/*    <span className="text-color_one text-24 font-bold mb-4">{t('Questions')} </span>*/}
      {/*</div>*/}

      <div className="mb-12">
        <div className="mb-6">
          {map(checksNotGrouped, (check, i) => {
            return renderCheck(check, i);
          })}
        </div>

        {map(checkGroups, (group, i) => {
          const checks =
            data && data.checks
              ? orderBy(
                  filter(data.checks, (f) => f.groupId === group.id),
                  ["priority", "description"],
                  ["desc", "asc"]
                )
              : [];
          if (checks.length) {
            return (
              <div key={`${group.id}`} className="mb-12">
                <div className="text-18 text-color_one font-bold mb-6">
                  {" "}
                  {group.description}
                </div>
                {map(checks, (check, i) => {
                  return renderCheck(check, i);
                })}
              </div>
            );
          }
        })}
      </div>

      {errors && errors["features"] && (
        <div className="flex items-center justify-center mb-2">
          <div className="text-color_error text-12"> {errors["features"]}</div>
        </div>
      )}

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "extra-info",
          toolStepNumber: "6",
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true,
        })}
        trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
          carConfigCode: inspection.key,
        })}
      />
    </div>
  );
};

export default Checks;
