import React, { useContext, useEffect, useState } from "react";
import WizardButtons from "../WizardButtons";
import { useMutation, useQuery } from "graphql-hooks";
import { map, orderBy } from "lodash";
import ColorPicker from "./generalState/ColorPicker";
import QualityPicker from "./generalState/QualityPicker";
import { findSelectOption } from "../../../utils";
import Select from "react-select";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const dataQuery = `query GeneralState {
 interiorColors {
    id    
    key
    name
    description
    colorCode
    priority
  }
  
  exteriorColors {
    id    
    key
    name
    description
    colorCode
    priority
  }
  
  interiorUpholstery {
    id    
    key
    name
    description  
    priority
  }
  
  interiorQualities {
    id    
    key
    name
    description  
    priority
  }
  
  exteriorQualities {
    id    
    key
    name
    description  
    priority
  }  
}`;

export const generalConditionMutation = `mutation SetGeneralCondition($key: String!, $data: GeneralConditionInputType!) { 
        setGeneralCondition(key: $key data: $data) {
            ok,           
        }
    }`;

const GeneralState = () => {
  const { loading, data, refetch: reFetchData } = useQuery(dataQuery);
  const [setGeneralCondition] = useMutation(generalConditionMutation);
  const { inspection, setField, setValidationErrorsModalVisible } =
    useContext(DataContext);
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      validate(false);
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "general-condition",
    toolStepNumber: "3",
    toolType: "inspection-form",
    requestType: "inspection",
    //inspectionPartTwo: true
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });

  useEffect(() => {
    reFetchData();
  }, [i18n.language]);

  const validate = (showModal = true) => {
    let errors = {};

    if (
      inspection.exteriorColor === undefined ||
      inspection.exteriorColor === ""
    ) {
      errors["exteriorColor"] = t("Please select the color of your car");
    }

    if (
      inspection.exteriorQuality === undefined ||
      inspection.exteriorQuality === ""
    ) {
      errors["exteriorQuality"] = t(
        "Please determine the condition of the car"
      );
    }

    if (
      inspection.interiorColor === undefined ||
      inspection.interiorColor === ""
    ) {
      errors["interiorColor"] = t("Please select the color of your interior");
    }

    if (
      inspection.interiorQuality === undefined ||
      inspection.interiorQuality === ""
    ) {
      errors["interiorQuality"] = t(
        "Please determine the state of the interior"
      );
    }

    if (
      inspection.interiorUpholstery === undefined ||
      inspection.interiorUpholstery === ""
    ) {
      errors["interiorUpholstery"] = t("Please select the type of upholstery");
    }

    if (
      inspection.tyreQualityFront === undefined ||
      inspection.tyreQualityFront === ""
    ) {
      errors["tyreQualityFront"] = t(
        "Please select the quality of the front tires"
      );
    }

    if (
      inspection.tyreQualityBack === undefined ||
      inspection.tyreQualityBack === ""
    ) {
      errors["tyreQualityBack"] = t(
        "Please select the quality of the rear tires"
      );
    }

    setErrors(errors);

    if (showModal && Object.keys(errors).length > 0) {
      Object.values(errors).map((message) => {
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: "general-condition",
          toolStepNumber: "3",
          errorType: "user-error",
          errorMessage: message,
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true
        });
        let event = {
          event: "tool-error",
          info: JSON.parse(errorTrackingInfo),
          commerce: JSON.parse(tagArgsCommerce),
        };
        aTrack.trackEvent(event);
      });
      setValidationErrorsModalVisible(errors);
    }

    return Object.keys(errors).length <= 0;
  };

  const validator = () => {
    if (validate()) {
      return setGeneralCondition({
        variables: {
          key: inspection.key,
          data: {
            interiorColor: inspection.interiorColor,
            interiorColorText: inspection.interiorColorText,
            interiorQuality: inspection.interiorQuality,
            interiorUpholstery: inspection.interiorUpholstery,
            interiorUpholsteryText: inspection.interiorUpholsteryText,
            exteriorColor: inspection.exteriorColor,
            exteriorColorText: inspection.exteriorColorText,
            exteriorQuality: inspection.exteriorQuality,
            tyreQualityFront: inspection.tyreQualityFront,
            tyreQualityBack: inspection.tyreQualityBack,
          },
        },
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.setGeneralCondition &&
          result.data.setGeneralCondition.ok
        ) {
          return true;
        }
        return false;
      });
    }
  };

  const interiorColors =
    data && data.interiorColors
      ? orderBy(data.interiorColors, ["priority"], ["asc"])
      : [];
  const exteriorColors =
    data && data.exteriorColors
      ? orderBy(data.exteriorColors, ["priority"], ["asc"])
      : [];
  const interiorQualities =
    data && data.interiorQualities
      ? orderBy(data.interiorQualities, ["priority"], ["asc"])
      : [];
  const exteriorQualities =
    data && data.exteriorQualities
      ? orderBy(data.exteriorQualities, ["priority"], ["asc"])
      : [];
  const interiorUpholsteryOptions =
    data && data.interiorUpholstery
      ? map(orderBy(data.interiorUpholstery, ["priority"]), (upholstery) => ({
          label: upholstery.description,
          value: upholstery.key,
          data: upholstery,
        }))
      : [];

  return (
    <div
      className="ml-10 mr-10 mt-0 lg:mt-12"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold mb-4">
            {t("Exterior color")} {inspection.exteriorColorText ? ":" : ""}
          </span>{" "}
          <span className="ml-2 text-18 font-bold">
            {inspection.exteriorColorText}
          </span>
        </div>
        <ColorPicker
          value={inspection.exteriorColor}
          colors={exteriorColors}
          onSelect={(color) => {
            setField("exteriorColor", color.key);
            setField("exteriorColorText", color.description);
          }}
          error={errors["exteriorColor"]}
        />
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Quality exterior")}
          </span>
        </div>
        <QualityPicker
          value={inspection.exteriorQuality}
          items={exteriorQualities}
          onSelect={(item) => {
            setField("exteriorQuality", item.key);
          }}
          error={errors["exteriorQuality"]}
        />
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Quality front tyres")}
          </span>
        </div>

        <div className="flex item-center justify-center ">
          <div
            className={`flex flex-1 flex-col border-2 rounded-lg mr-5 h-32 items-center justify-center text-12 font-bold  uppercase tracking-wider ${
              inspection.tyreQualityFront === ">3mm"
                ? "text-color_one border-color_one"
                : "text-color_three border-color_four"
            }`}
            onClick={() => setField("tyreQualityFront", ">3mm")}
          >
            <div className="flex flex-1 items-center justify-center">
              <i
                className={`icon-high ${
                  inspection.tyreQualityFront === ">3mm"
                    ? "text-color_one"
                    : "text-black"
                }`}
                style={{ fontSize: 75 }}
              />
            </div>
            <div className="flex items-center h-8 text-center">
              {t("> 3mm")}
            </div>
          </div>

          <div
            className={`flex flex-1 flex-col border-2 rounded-lg h-32 items-center justify-center text-12 font-bold uppercase tracking-wider ${
              inspection.tyreQualityFront === "<3mm"
                ? "text-color_one border-color_one"
                : "text-color_three border-color_four"
            }`}
            onClick={() => setField("tyreQualityFront", "<3mm")}
          >
            <div className="flex flex-1 items-center justify-center">
              <i
                className={`icon-low ${
                  inspection.tyreQualityFront === "<3mm"
                    ? "text-color_one"
                    : "text-black"
                }`}
                style={{ fontSize: 75 }}
              />
            </div>
            <div className="flex items-center h-8 text-center">
              {t("< 3mm")}
            </div>
          </div>
        </div>

        {errors["tyreQualityFront"] && (
          <div className="text-color_error text-12 mt-2">
            {" "}
            * {errors["tyreQualityFront"]}{" "}
          </div>
        )}
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Quality rear tyres")}
          </span>
        </div>

        <div className="flex item-center justify-center ">
          <div
            className={`flex flex-1 flex-col border-2 rounded-lg mr-5 h-32 items-center justify-center text-12 font-bold  uppercase tracking-wider ${
              inspection.tyreQualityBack === ">3mm"
                ? "text-color_one border-color_one"
                : "text-color_three border-color_four"
            }`}
            onClick={() => setField("tyreQualityBack", ">3mm")}
          >
            <div className="flex flex-1 items-center justify-center">
              <i
                className={`icon-high ${
                  inspection.tyreQualityBack === ">3mm"
                    ? "text-color_one"
                    : "text-black"
                }`}
                style={{ fontSize: 75 }}
              />
            </div>
            <div className="flex items-center h-8 text-center">
              {t("> 3mm")}
            </div>
          </div>

          <div
            className={`flex flex-1 flex-col border-2 rounded-lg h-32 items-center justify-center text-12 font-bold uppercase tracking-wider ${
              inspection.tyreQualityBack === "<3mm"
                ? "text-color_one border-color_one"
                : "text-color_three border-color_four"
            }`}
            onClick={() => setField("tyreQualityBack", "<3mm")}
          >
            <div className="flex flex-1 items-center justify-center">
              <i
                className={`icon-low ${
                  inspection.tyreQualityBack === "<3mm"
                    ? "text-color_one"
                    : "text-black"
                }`}
                style={{ fontSize: 75 }}
              />
            </div>
            <div className="flex items-center h-8 text-center">
              {t("< 3mm")}
            </div>
          </div>
        </div>

        {errors["tyreQualityBack"] && (
          <div className="text-color_error text-12 mt-2">
            {" "}
            * {errors["tyreQualityBack"]}{" "}
          </div>
        )}
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Color interior")} {inspection.interiorColor ? ":" : ""}
          </span>{" "}
          <span className="ml-2 text-18 font-bold">
            {inspection.interiorColorText}
          </span>
        </div>
        <ColorPicker
          value={inspection.interiorColor}
          colors={interiorColors}
          onSelect={(color) => {
            setField("interiorColor", color.key);
            setField("interiorColorText", color.description);
          }}
          error={errors["interiorColor"]}
        />
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Interior upholstery")}
          </span>
        </div>
        <div>
          <Select
            value={findSelectOption(
              interiorUpholsteryOptions,
              inspection.interiorUpholstery
            )}
            options={interiorUpholsteryOptions}
            isLoading={loading}
            onChange={(option) => {
              setField("interiorUpholstery", option.value);
              setField("interiorUpholsteryText", option.data.name);
            }}
            placeholder={t("What material is your car interior's upholstery?")}
            className="cursor-pointer"
            classNamePrefix="SelectField"
            isSearchable={false}
          />
        </div>
        {errors["interiorUpholstery"] && (
          <div className="text-color_error text-12 ml-5 mt-2">
            {" "}
            * {errors["interiorUpholstery"]}{" "}
          </div>
        )}
      </div>

      <div className="mb-8">
        <div className="mb-6">
          <span className="text-color_one text-24 font-bold">
            {t("Quality interior")}
          </span>
        </div>
        <QualityPicker
          value={inspection.interiorQuality}
          items={interiorQualities}
          onSelect={(item) => {
            setField("interiorQuality", item.key);
          }}
          error={errors["interiorQuality"]}
        />
      </div>

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "general-condition",
          toolStepNumber: "3",
          toolType: "inspection-form",
          requestType: "inspection",
          //inspectionPartTwo: true
        })}
        trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
          carConfigCode: inspection.key,
        })}
      />
    </div>
  );
};

export default GeneralState;
