import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import DataContext from "../../data/context";
import {find} from "lodash";
import {formatFirstRegistration, formatNumber} from "../../utils";

const VehicleSummary = ({onClose}) => {
    const {inspection, data} = useContext(DataContext);
    const {t} = useTranslation();

    const findValue = (collection, value, resultField) => {
        const tmp = find(collection, (i) => i.key === value);
        if (tmp && tmp[resultField]) {
            return tmp[resultField];
        } else {
            return formatValue(undefined);
        }
    };

    const formatValue = (value) => {
        return (value) ? value : '';
    }

    const itemClass = `vehicle-summary-item flex justify-between border-b ${(onClose) ? 'border-color_one' : 'border-white'} pb-2 mb-6`;

    return (
        <div className="mt-1 w-full h-full">
            {onClose &&
            <div className="flex items-center justify-between text-color_one pl-8 pr-8 font-bold " style={{height: 50}}>
                <div><i className="fas fa-info-circle text-14 mr-2"/>{t('Vehicle specifications')}</div>
                <div><i className="fal fa-times text-18 cursor-pointer font-bold" onClick={onClose}/></div>
            </div>}

            <div className="flex flex-col w-full h-full pl-8 pr-8 pt-8 pb-8 truncate ">

                <div className={itemClass}>
                    <div className="">{t('Make')}</div>
                    <div className="font-bold">{findValue(data.makes, inspection.make, 'name')}</div>
                </div>

                <div className={itemClass}>
                    <div className="">{t('First registration')}</div>
                    <div className="font-bold">{formatFirstRegistration(inspection.firstRegistrationYear, inspection.firstRegistrationMonth)}</div>
                </div>

                <div className={itemClass}>
                    <div className="">{t('Model')}</div>
                    <div className="font-bold">{findValue(data.models, inspection.model, 'name')}</div>
                </div>

                <div className={itemClass}>
                    <div className="">{t('Bodytype')}</div>
                    <div className="font-bold">{findValue(data.bodies, inspection.body, 'description')}</div>
                </div>

                <div className={itemClass}>
                    <div className="">{t('Fuel')}</div>
                    <div className="font-bold">{findValue(data.fuels, inspection.fuel, 'description')}</div>
                </div>

                <div className={itemClass}>
                {inspection.fuel === 'elektrisch' ? 
                    <>
                        <div className="">{t('EnginePower')}</div>
                        <div className="font-bold">{findValue(data.powers, inspection.powerRange, 'description')}</div>
                    </> : 
                    <>
                        <div className="">{t('EngineCC')}</div>
                        <div className="font-bold">{findValue(data.engineRanges, inspection.engineRange, 'description')}</div>
                    </>
                }

                </div>

                <div className={itemClass}>
                    <div className="">{t('Transmission')}</div>
                    <div className="font-bold">{findValue(data.transmissions, inspection.transmission, 'description')}</div>
                </div>

                <div className={itemClass}>
                    <div className="">{t('Mileage')}</div>
                    <div className="font-bold">{formatValue((inspection.mileage) ? formatNumber(inspection.mileage) : undefined)} {inspection.mileage ? 'km' : ''}</div>
                </div>

            </div>
        </div>
    );
};


export default VehicleSummary;
