import React from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

const ValuationNextSteps = () => {
  const { t } = useTranslation();

  // Each step has a unique key, an image and a text object
  const steps = [
    {
      key: "valuation-step-1",
      image: require("../../assets/steps/step-1.svg"),
      text: {
        __html: t("{{ styling }} Inspect {{stylingEnd}} the car yourself", {
          styling: '<span class="text-color_mywaybuy_two">',
          stylingEnd: "</span />",
          interpolation: { escapeValue: false },
        }),
      },
    },
    {
      key: "valuation-step-2",
      image: require("../../assets/steps/step-2.svg"),
      text: {
        __html: t(
          "{{ styling }} Receive an offer {{stylingEnd}} without committing",
          {
            styling: '<span class="text-color_mywaybuy_two">',
            stylingEnd: "</span />",
            interpolation: { escapeValue: false },
          }
        ),
      },
    },
    {
      key: "valuation-step-3",
      image: require("../../assets/steps/step-3.svg"),
      text: {
        __html: t(
          "Confirm the sale from {{ styling }} your own home {{stylingEnd}}",
          {
            styling: '<span class="text-color_mywaybuy_two">',
            stylingEnd: "</span />",
            interpolation: { escapeValue: false },
          }
        ),
      },
    },
    {
      key: "valuation-step-4",
      image: require("../../assets/steps/step-4.svg"),
      text: {
        __html: t(
          "Receive your payment in {{ styling }} 24 hours {{stylingEnd}}",
          {
            styling: '<span class="text-color_mywaybuy_two">',
            stylingEnd: "</span />",
            interpolation: { escapeValue: false },
          }
        ),
      },
    },
  ];

  // Returns formatted JSX for a single step
  const renderStep = (index, key, image, text) => {
    return (
      <div key={key}>
        <img
          className="mb-5"
          src={image}
          alt={`Step ${index + 1} after valuation`}
        />
        <p
          className="font-bold text-center text-14 md:text-16"
          dangerouslySetInnerHTML={text}
        ></p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-10 md:gap-20">
      {map(steps, (step, index) => {
        return renderStep(index, step.key, step.image, step.text);
      })}
    </div>
  );
};

export default ValuationNextSteps;
