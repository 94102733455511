import React from "react";
import { useTranslation } from "react-i18next";

import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../../tagManager";

const LandingFooter = () => {
  const { t, i18n } = useTranslation();

  const scrollTo = (id) => {
    if (!document) return;
    return document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <footer className="overflow-x-hidden py-0 md:py-10 pb-10 font-mywaybuy text-color_mywaybuy_one">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="order-first md:order-last xl:order-first w-full xl:w-2/6 mb-6">
            <div className="max-w-xs">
              <img
                src={require("../../assets/landing-page/mywaybuy-logo-horizontal.svg")}
                alt="my way buy logo horizontal"
                className="mb-4 w-60"
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Sell your car {{ styling }}cheaply, easily and safely{{ stylingEnd}} with My Way Buy",
                    {
                      styling: "<strong>",
                      stylingEnd: "</strong>",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
                className="mb-4 text-color_mywaybuy_four text-base leading-normal"
              ></p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 xl:w-1/6">
            <p className="font-bold text-color_mywaybuy_one text-xl pb-4 pt-2">
              {t("Company")}
            </p>
            <a
              href="#who"
              onClick={(e) => {
                e.preventDefault();
                scrollTo("who");
              }}
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("What do we offer")}
            </a>
            <a
              href="#how"
              onClick={(e) => {
                e.preventDefault();
                scrollTo("how");
              }}
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("How does it work")}
            </a>
            <a
              href="#faq"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
              onClick={(e) => {
                e.preventDefault();
                scrollTo("faq");
              }}
            >
              {t("Questions?")}
            </a>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 xl:w-1/6">
            <p className="font-bold text-color_mywaybuy_one text-xl pb-4 pt-2">
              {t("Legal")}
            </p>
            <a
              href={
                i18n?.language === "nl"
                  ? "https://mywaybuy.be/default/privacy?language=nl"
                  : "https://mywaybuy.be/default/privacy?language=fr"
              }
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("Privacy policy")}
            </a>
            <a
              href={
                i18n?.language === "nl"
                  ? "https://mywaybuy.be/default/legal?language=nl"
                  : "https://mywaybuy.be/default/legal?language=fr"
              }
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("Terms of service")}
            </a>{" "}
            <a
              href={
                i18n?.language === "nl"
                  ? "https://www.dieteren.be/nl/dieteren-cookie-policy"
                  : "https://www.dieteren.be/fr/dieteren-cookie-policy"
              }
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("Cookie policy")}
            </a>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 xl:w-1/6">
            <p className="font-bold text-color_mywaybuy_one text-xl pb-4 pt-2">
              {t("Legal")}
            </p>
            <a
              href={
                i18n.language === "nl"
                  ? "https://myway.be/nl"
                  : "https://myway.be/fr"
              }
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              MyWay (B2C)
            </a>{" "}
            <a
              href="https://mywaypro.be"
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              MyWay Pro (B2B)
            </a>
            <a
              href={
                i18n?.language === "nl"
                  ? "https://www.dieteren.be/media/cwmhzwfv/one-pager-klokkenluiden-d-ieteren-nl-2020.pdf"
                  : "https://www.dieteren.be/media/xjdfulbz/one-pager-alerte-int%C3%A9grit%C3%A9-d-ieteren-fr-2020.pdf"
              }
              target="_blank"
              className="text-base leading-normal text-color_mywaybuy_four block pb-2"
            >
              {t("Whistle blow")}
            </a>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/4 xl:w-1/6">
            <p className="font-bold text-color__one text-xl pb-4 pt-2">
              {t("Get in touch")}
            </p>
            <p className="block pb-2 text-base leading-normal text-color_mywaybuy_four">
              {t("Did not find what you were looking for?")}
            </p>
            <a
              href="mailto:info@mywaybuy.be"
              className="my-4 flex items-center transition-all transform shadow-xl rounded-full relative max-w-sm bg-white"
              onClick={() => {
                //Track buttonclick
                let aTrack = analyticstracker();
                let trackingInfo = TAGMANAGER_BUTTON_ARGS({
                  buttonName: "to-mail",
                  buttonText: t("Send an e-mail"),
                  buttonType: "button",
                  buttonCategory: "outbound-link",
                  buttonTarget: "mailto:info@mywaybuy.be",
                  buttonPosition: "home-footer",
                });
                let event = {
                  event: "button-click",
                  info: JSON.parse(trackingInfo),
                };
                aTrack.trackEvent(event);
              }}
            >
              <div className="w-8 flex-none m-1">
                <img
                  src={require("../../assets/landing-page/seller-face.svg")}
                  alt="myway buy seller header"
                  className="w-full"
                />
              </div>
              <div className="flex-grow ml-2 mr-4">
                <p className="text-color_mywaybuy_one font-bold text-xs leading-none">
                  {t("Questions?")}
                </p>
                <p className="text-color_mywaybuy_four text-xs leading-relaxed">
                  {t("Send an e-mail")}
                </p>
              </div>
              <div className="w-8 h-8 flex-none bg-color_mywaybuy_error rounded-full flex justify-center items-center m-1">
                <img
                  src={require("../../assets/landing-page/enveloppe.svg")}
                  alt="icon envelope"
                  className="w-full p-2"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
