import React, {useContext} from 'react';
import DataContext from "../data/context";
import { getDealerFromUrl } from "../utils";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Scrollbar from "./Scrollbar";
import HtmlBlock from "./HtmlBlock";
import LandingFooter from "./landing-page/LandingFooter";

const Footer = () => {
  const { dealer, dealerConfig } = useContext(DataContext);
  let history = useHistory();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();

  if (!dealer) {
    return null;
  }

  const hasContactInformation = (dealerData) => {
    return (
      dealerData.address !== "" ||
      dealerData.postal !== "" ||
      dealerData.city !== "" ||
      dealerData.telephone !== "" ||
      dealerData.email !== ""
    );
  };

  const visible = hasContactInformation(dealer) || dealerConfig.footer;

  const handlePrivacyClick = () => {
    history.push(`/${getDealerFromUrl(window.location.pathname)}/privacy`);

    // if(dealerConfig && dealerConfig.privacyUrl && dealerConfig.privacyUrl !== ""){
    //     window.open(parseUrl(dealerConfig.privacyUrl, i18n.language))
    // } else {
    //     history.push(`/${getDealerFromUrl(window.location.pathname)}/privacy`)
    // }
  };

  const handleLegalClick = () => {
    history.push(`/${getDealerFromUrl(window.location.pathname)}/legal`);

    // if(dealerConfig && dealerConfig.termsAndConditionsUrl && dealerConfig.termsAndConditionsUrl !== ""){
    //     window.open(parseUrl(dealerConfig.termsAndConditionsUrl, i18n.language))
    // } else {
    //     history.push(`/${getDealerFromUrl(window.location.pathname)}/legal`)
    // }
  };

  const getCopyrightLogo = () => {
    return dealerConfig.copyrightLogo
      ? `/branding/${dealerConfig.copyrightLogo}`
      : undefined;
  };

  if (location && location.pathname?.includes("/landing")) {
    return <LandingFooter />;
  }

  return (
    <React.Fragment>
      {visible && (
        <div className="w-full flex flex-col bg-color_four items-center ">
          <div
            className="flex flex-col flex-1 items-between md:flex-row md:w-3/5 pl-8 pr-8 pt-12 pb-12"
            style={{ maxWidth: 1024 }}
          >
            <div className="flex flex-col md:w-2/5">
              <div className="font-md font-bold mb-6 tracking-wide">
                {dealer.name}
              </div>
              <div className="font-md tracking-wide text-color_three mb-1">
                {dealer.address}
              </div>
              <div className="font-md tracking-wide text-color_three mb-6">
                {dealer.postal} {dealer.city}
              </div>

              {dealer.telephone && dealer.telephone !== "" && (
                <div className="font-md tracking-wide text-color_three mb-1">
                  T: {dealer.telephone}
                </div>
              )}

              {dealer.email && dealer.email !== "" && (
                <div className="font-md tracking-wide text-color_three mb-6">
                  E:{" "}
                  <a
                    className="underline cursor-pointer text-blue-600"
                    href={`mailto:${dealer.email}`}
                  >
                    {" "}
                    {dealer.email}{" "}
                  </a>
                </div>
              )}

              <div className="text-color_one text-24">
                {dealer.facebook && (
                  <a href={dealer.facebook}>
                    <i className="fab fa-facebook-square mr-2" />
                  </a>
                )}

                {dealer.instagram && (
                  <a href={dealer.instagram}>
                    <i className="fab fa-instagram-square mr-2" />
                  </a>
                )}

                {dealer.twitter && (
                  <a href={dealer.twitter}>
                    <i className="fab fa-twitter-square mr-2" />
                  </a>
                )}
              </div>
            </div>

            {dealerConfig &&
              dealerConfig.footer &&
              dealerConfig.footer.html &&
              dealerConfig.footer.html !== "" && (
                <div className="flex flex-col md:w-3/5">
                  <div className="flex flex-1 items-center justify-center">
                    <Scrollbar>
                      <HtmlBlock
                        html={dealerConfig.footer.html}
                        script={dealerConfig.footer.script}
                      />
                    </Scrollbar>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      <div
        className="footer-right-container w-full flex flex-col items-center "
        style={{ height: 50, backgroundColor: "#344248" }}
      >
        <div
          className="footer-right pl-4 pr-4 flex container flex-1 md:justify-between text-white"
          style={{ maxWidth: 1024 }}
        >
          <div className="mr-4 flex items-center">
            {dealerConfig.copyrightLogo === "" && (
              <a
                href="https://www.autralis.com"
                target="_blank"
                style={{ height: 15 }}
              >
                <img
                  src={require("../assets/logo-autralis-white.svg")}
                  alt="autralis"
                  style={{ height: 15 }}
                />
              </a>
            )}

            {dealerConfig.copyrightLogo &&
              dealerConfig.copyrightLogo !== "" && (
                <img
                  className="copyright-logo"
                  src={getCopyrightLogo()}
                  alt=""
                  style={{ height: 15 }}
                />
              )}
          </div>

          <div className="flex items-center">
            <div
              className="hover:underline cursor-pointer"
              onClick={handleLegalClick}
            >
              {t("Terms & conditions")}
            </div>
            <span className="mr-2 ml-2">|</span>
            <div
              className="hover:underline cursor-pointer mr-4"
              onClick={handlePrivacyClick}
            >
              {t("Privacy policy")}
            </div>

            <span className="text-14 hidden md:block">
              {dealerConfig.copyright && dealerConfig.copyright !== ""
                ? dealerConfig.copyright
                : "Autralis B.V."}{" "}
              © {moment().year()}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
