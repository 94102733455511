import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { filter, keys, map, orderBy } from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import TagManager from "react-gtm-module";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const Model = () => {
  const { inspection, data, setField, setNextVehicleDataStep } =
    useContext(DataContext);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "model",
    toolStepNumber: "3",
    requestType: "car-quotation",
  });

    useEffect(() => {
    // IF a model is already set (e.g. by the URL parameters), skip this
    if (inspection.model) {
        setNextVehicleDataStep();
    }
    }, [inspection]);

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};

    if (!inspection.model) {
      errors["model"] = t("Please select the model of your car");
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "model",
        toolStepNumber: "3",
        requestType: "car-quotation",
        errorType: "user-error",
        errorMessage: errors.model,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const handleMakeClick = (e, model) => {
    setField("model", model.key);
    let aTrack = analyticstracker();
    aTrack.trackInteraction(e);
    setNextVehicleDataStep();
  };

  const allModels =
    data && data.models ? filter(data.models, (m) => !m.disabled) : [];
  const modelOptions = orderBy(allModels, ["name"], ["asc"]);

  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Model")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t("Select the model of your car")}:
      </div>

      <div className="flex flex-wrap mb-10">
        {map(modelOptions, (model, i) => {
          const selected = model.key === inspection.model;
          const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "model",
            toolStepNumber: "3",
            requestType: "car-quotation",
            toolStepOption: model.name,
          });

          return (
            <div
              key={`model_${model.key}`}
              className={`w-1/3 mb-2 ${i % 3 !== 2 ? "pr-2" : ""}`}
            >
              <div
                className={`flex items-center justify-center text-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                  selected
                    ? "border-color_one text-color_one"
                    : "border-color_five text-color_three"
                } hover:border-color_one hover:text-color_one`}
                data-tracking-event="tool-submit"
                data-tracking-commerce={tagArgsCommerce}
                data-tracking-info={tagArgsSubmit}
                style={{ height: 70 }}
                onClick={(e) => handleMakeClick(e, model)}
              >
                {model.name}
              </div>
            </div>
          );
        })}
      </div>

      <WizardError errors={errors} />

      <WizardButtons validatorFunction={validator} />
    </div>
  );
};

export default Model;
