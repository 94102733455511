import React, { useEffect } from "react";
import StatusGeneral from "../components/StatusGeneral";
import { useTranslation } from "react-i18next";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_PAGE_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
  TAGMANAGER_TOOL_ARGS,
  getPreviousPageData,
  getPreviousToolCompleteData,
} from "../../../tagManager";

const Initial = () => {
  const { t, i18n } = useTranslation();
  const hostname = window.location.hostname; //window.location.href;
  const prevPageData = getPreviousPageData(window.dataLayer);
  const prevToolCompleteData = getPreviousToolCompleteData(window.dataLayer);

  useEffect(() => {
    analyticstracker().trackImpression("page-impression");

    // Track tool-complete on successpage
    // Only for MyWay && when the last tool-complete is not an earlier one of same type
    if (
      hostname.includes("myway") &&
      //prevToolCompleteData &&
      prevToolCompleteData?.info?.toolName !== "myway-buy-inspection"
    ) {
      let trackingEvent = "tool-complete";
      let trackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "complete",
        toolStep: "thank-you",
        toolStepNumber: "8",
        toolName: "myway-buy-inspection",
        toolType: "inspection-form",
        requestType: "inspection",
      });
      let trackingCommerce = TAGMANAGER_COMMERCE_ARGS({
        carConfigCode: window.location.href.split("/").pop(),
      });

      let aTrack = analyticstracker();
      let event = {
        event: trackingEvent,
        info: JSON.parse(trackingInfo),
        commerce: JSON.parse(trackingCommerce),
      };
      aTrack.trackEvent(event);
    }

    //
  }, []);

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    pageName: hostname.includes("myway")
      ? "myway-buy/inspection/thank-you"
      : "car-quotation/valuation-2",
    subSectionOne: hostname.includes("myway") ? "thank-you" : "",
    section: hostname.includes("myway") ? "inspection" : "valuation-2",
    language: i18n.language,
    previousBrand: prevPageData?.pageInfo?.brand
      ? prevPageData?.pageInfo?.brand
      : "",
    previousPageFullUrl: prevPageData?.pageInfo?.pageFullUrl
      ? prevPageData?.pageInfo?.pageFullUrl
      : "",

    previousPageName: prevPageData?.pageInfo?.pageName
      ? prevPageData?.pageInfo?.pageName
      : "",
    previousPageType: prevPageData?.pageInfo?.pageType
      ? prevPageData?.pageInfo?.pageType
      : "",
    previousPageUrl: prevPageData?.pageInfo?.pageUrl
      ? prevPageData?.pageInfo?.pageUrl
      : "",
    previousPlatform: prevPageData?.pageInfo?.platform
      ? prevPageData?.pageInfo?.platform
      : "",
  });

  return (
    <React.Fragment>
      <StatusGeneral title={t("Your request has been submitted")} />

      <div
        className="md:w-3/4"
        data-tracking-event="page-impression"
        data-tracking-info={tagInfo}
      >
        <div className="text-color_three mb-8 mt-8 leading-6">
          <p
            className="leading-6"
            dangerouslySetInnerHTML={{
              __html: t(
                "We confirm our offer within {{styling}} 24 hours {{stylingEnd}} and guarantee it for 7 days.",
                {
                  styling: '<span class="font-bold text-color_one">',
                  stylingEnd: "</span />",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
          ></p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Initial;
