import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { keys, map, orderBy } from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const Transmission = () => {
  const { inspection, data, setField, setNextVehicleDataStep } =
    useContext(DataContext);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "car-gearbox-type",
    toolStepNumber: "7",
    requestType: "car-quotation",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};

    if (!inspection.transmission) {
      errors["transmission"] = t("Please select a transmission");
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "car-gearbox-type",
        toolStepNumber: "7",
        requestType: "car-quotation",
        errorType: "user-error",
        errorMessage: errors.transmission,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const handleTransmissionClick = (e, transmission) => {
    setField("transmission", transmission.key);
    let aTrack = analyticstracker();
    aTrack.trackInteraction(e);

    setNextVehicleDataStep();
  };

  const transmissionOptions =
    data && data.transmissions
      ? orderBy(data.transmissions, ["description"], ["asc"])
      : [];

  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Transmission")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t("Select the transmission of your vehicle")}:
      </div>

      <div className="flex mb-10">
        {map(transmissionOptions, (transmission, i) => {
          const selected = transmission.key === inspection.transmission;
          const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: "submit",
            toolStep: "car-gearbox-type",
            toolStepNumber: "7",
            requestType: "car-quotation",
            toolStepOption: transmission.description,
          });
          return (
            <div
              key={`transmission_${transmission.key}`}
              className={`w-1/2 mb-2 ${i % 2 !== 1 ? "pr-2" : ""}`}
            >
              <div
                className={`flex items-center flex-1 justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                  selected
                    ? "border-color_one text-color_one"
                    : "border-color_five text-color_three"
                } hover:border-color_one hover:text-color_one`}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                data-tracking-commerce={tagArgsCommerce}
                style={{ height: 70 }}
                onClick={(e) => handleTransmissionClick(e, transmission)}
              >
                {transmission.description}
              </div>
            </div>
          );
        })}
      </div>

      <WizardError errors={errors} />

      <WizardButtons validatorFunction={validator} />
    </div>
  );
};

export default Transmission;
